const PuntosRecarga = (props) => (
  <svg
    {...props}
    width={30}
    height={26}
    fill="none"
  >
    <g clip-path="url(#clip0_2_213)">
      <path d="M13.495 20.9962C10.1078 20.9962 6.72008 20.9962 3.332 20.9962C1.21027 20.9962 0.00128174 19.7767 0.00128174 17.647C0.00128174 12.8894 0.00128174 8.13467 0.00128174 3.38286C0.00128174 1.21563 1.22536 0.00242931 3.45004 0.00242931C8.86611 -0.00424809 14.2826 -0.00424809 19.6995 0.00242931C21.3065 0.00242931 22.921 -0.0125948 24.5192 0.127631C25.9693 0.252832 26.9925 1.50484 26.995 2.96719C27.0025 7.99277 27.0025 13.0188 26.995 18.0452C26.995 19.7266 25.6805 20.9824 23.9103 20.9912C20.4377 21.0074 16.9664 20.9962 13.495 20.9962ZM13.495 19.5513C16.9459 19.5513 20.3963 19.5513 23.8463 19.5513C25.0792 19.5513 25.6039 19.0318 25.6039 17.8048C25.6039 12.9453 25.6039 8.08626 25.6039 3.22761C25.6039 2.00189 25.0804 1.4748 23.8463 1.4748C16.9664 1.4748 10.0864 1.4748 3.20645 1.4748C1.9309 1.4748 1.40614 1.99313 1.40614 3.25015C1.40614 8.09126 1.40614 12.9295 1.40614 17.7647C1.40614 19.003 1.95475 19.5463 3.2052 19.5463C6.63178 19.5514 10.0596 19.553 13.4887 19.5513H13.495Z" fill="currentColor" />
      <path d="M13.4372 13.4954C10.8853 13.4954 8.33248 13.4954 5.77887 13.4954C4.8674 13.4954 4.54602 13.3076 4.53597 12.7918C4.52468 12.2384 4.86867 12.0231 5.79144 12.0231C10.9363 12.0231 16.0837 12.0231 21.2336 12.0231C21.2964 12.0231 21.3592 12.0231 21.422 12.0231C21.9179 12.0506 22.4263 12.0807 22.4778 12.738C22.5192 13.245 22.1928 13.4892 21.4132 13.4892C18.7553 13.5017 16.0938 13.4954 13.4372 13.4954Z" fill="currentColor" />
      <path d="M20.3159 3.81238C20.7968 3.81238 21.2776 3.80487 21.7572 3.81238C22.8268 3.82991 23.5776 4.53729 23.614 5.60275C23.6429 6.39403 23.6429 7.1878 23.614 7.98158C23.5985 8.44542 23.4035 8.88527 23.07 9.20898C22.7365 9.53269 22.2904 9.71515 21.825 9.71812C20.8022 9.73816 19.7782 9.73816 18.7529 9.71812C18.517 9.71714 18.2837 9.66975 18.0662 9.57868C17.8487 9.48761 17.6514 9.35464 17.4856 9.18739C17.3197 9.02014 17.1886 8.8219 17.0997 8.60403C17.0109 8.38616 16.966 8.15294 16.9676 7.91773C16.9551 7.14649 16.9576 6.37525 16.9676 5.60401C16.9618 5.14063 17.1394 4.69365 17.4618 4.35993C17.7842 4.02622 18.2256 3.83263 18.6901 3.82114C19.2274 3.79861 19.7723 3.81238 20.3159 3.81238ZM20.2192 8.27706C20.6787 8.27706 21.1382 8.26579 21.6003 8.27706C22.0183 8.28958 22.2167 8.11554 22.2092 7.68736C22.1966 7.06135 22.2004 6.43534 22.2092 5.80934C22.2092 5.39117 22.0233 5.20587 21.6015 5.21088C20.7227 5.2209 19.8439 5.21965 18.9738 5.21088C18.562 5.21088 18.3461 5.38741 18.3461 5.80559C18.3461 6.43159 18.3461 7.0576 18.3461 7.6836C18.3461 8.09301 18.547 8.28958 18.9738 8.2758C19.4007 8.26203 19.8024 8.27706 20.2192 8.27706Z" fill="currentColor" />
      <path d="M14.2357 16.499C13.318 16.499 12.4002 16.499 11.4825 16.499C11.0042 16.499 10.5936 16.3738 10.571 15.7966C10.5484 15.2195 10.9853 15.0454 11.4436 15.0454C13.3201 15.0262 15.197 15.0262 17.0743 15.0454C17.5351 15.0454 17.9695 15.1806 17.9532 15.7841C17.9356 16.4201 17.4761 16.4903 16.9889 16.494C16.0674 16.504 15.1497 16.499 14.2357 16.499Z" fill="currentColor" />
      <path d="M6.72925 16.4965C6.27227 16.4965 5.81403 16.5115 5.35704 16.4965C4.92516 16.479 4.58243 16.3287 4.54979 15.8192C4.51715 15.3096 4.8172 15.0867 5.26665 15.0767C6.26349 15.0567 7.26031 15.063 8.25714 15.0767C8.67521 15.0842 8.90999 15.3409 8.91878 15.7553C8.92882 16.2098 8.65136 16.4527 8.22701 16.479C7.72483 16.5115 7.22893 16.4865 6.72925 16.4865V16.4965Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2_213">
        <rect width="27" height="21" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default PuntosRecarga;
