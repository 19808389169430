import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alimentadoraActive: "",
};

export const alimentadoraActiveSlice = createSlice({
  name: "alimentadoraActive",
  initialState,
  reducers: {
    changedAlimentadoraActive: (state, action) => {
      state.alimentadoraActive = action.payload;
    },
  },
});

export const { changedAlimentadoraActive } = alimentadoraActiveSlice.actions;
export default alimentadoraActiveSlice.reducer;