import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export const Nacional = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bgcolor: 'primary.white',
        px: {
          xs: 2,
          md: 10,
        },
        py: 5,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mt: 10,
          mb: 5,
          color: 'primary.gold',
          textAlign: {
            xs: 'center',
            md: 'left',
          },
          fontWeight: 'bold',
        }}
      >
        CÓDIGO DE CONDUCTA NACIONAL
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          lg={6}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'primary.black',
              textAlign: 'justify',
              mb: 3,
            }}
          >
            El sistema cuenta con la certificación del Código de Conducta Nacional,
            a través de la SECTUR, con el cual se establecen medidas para proteger
            a las niñas, niños y adolescentes en contra de cualquier tipo de explotación
            y trata de personas; conductores del Sistema Tuzobús, personal de limpieza,
            personal de SITMAH y PIBEH son parte de las campañas y cursos de sensibilización
            para la identificación y acciones de prevención.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: 'primary.black',
              textAlign: 'justify',
            }}
          >
            El Código Conducta Nacional para la Protección de las Niñas, Niños y Adolescentes
            en el Sector de los Viajes y el Turismo (CCN), es un instrumento creado por la
            Secretaría de Turismo como un compromiso que adquiere voluntariamente la industria
            turística, para proteger a las niñas, niños y adolescentes en contra de la explotación
            sexual y laboral de niñas, niños y adolescentes y el trabajo infantil, a través de la
            implementación de 6 directrices en sus empresas o establecimientos.<br />
            El CCN compromete a los actores sociales de la industria turística, a mejorar la
            calidad de vida de las comunidades, protegiendo los derechos de las personas menores
            de edad, contra la explotación sexual y laboral, así como a crear conciencia en los
            turistas para fomentar responsabilidades y compromisos que a su vez proyecten la
            imagen de un turismo seguro en México.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          lg={6}
          
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="/img/codigos/img1.png"
            alt="Código de Conducta Nacional"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};


