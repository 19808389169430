import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const veda = true

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#BC955B',
      white: '#fff',
      wineLight: '#A02142',
      wine: '#691B32',
      wineDark: '#3A0C18',
      gold: '#BC955B',
      black: '#000000',
      greenLight: '#86BE0A',
      greenDark: '#1B4805',
      yellowLight: '#FFC700',
      orange: '#FF8A00',
      redLight: '#F43C3C',
      redDark: '#C90606',
      backgroundLight: '#EDEDED',
      textLight: '#6F7271',
      textDark: '#454545',
      blueIcon: '#24BDFF',
    },
    text: {
      primary: '#454545',
      light: '#A4A4A4',
    },
    routes: {
      green: '#50AF24',
      red: '#C90606',
      purpule: '#6B096D',
      orange: '#FF8A00',
    },
    green: {
      main: '#50AF24',
      station: '#50AF24',
      secondary: '#BFD295',
      contrastText: '#44951D',
    },
    error: {
      main: red.A400,
    },
    gob: {
      greenTuzo: veda ? '#5a8a00' : '#5a8a00',
      white: '#F4F4F4',
      gray: veda ? '#5C5B5C' : '#98989A',
      pink: veda ? '#9E2343' : '#5C5B5C',
      wine: veda ? '#691B32' : '#3D3D3D',
      gold: veda ? '#BC955B' : '#9E9E9E',
      goldLight: veda ? '#DEC9A3' : '#D8D8D8',
      greenI: veda ? '#265B4E' : '#3D3D3D',
      green: veda ? 'green' : '#3D3D3D',
      whiteBackground: veda ? '#F4F4F4' : '#691B32',
    },
  },
});

export default theme;