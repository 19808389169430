import React, { useState } from 'react';
import { MenuItem, Menu, ListItemText, Box } from '@mui/material';

const MenuItemCustom = ({ children, subItems, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuItem onClick={handleClick} {...props}>
        <ListItemText primary={children} />
      </MenuItem>
      {subItems && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {subItems.map((subItem, index) => (
            <Box key={index} onClick={handleClose} sx={{ cursor: 'pointer' }}>
              {subItem}
            </Box>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default MenuItemCustom;
