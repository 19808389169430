import { useTheme } from '@emotion/react';
import { Box, Typography, Grid } from '@mui/material';

export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      minWidth="lg"
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: '200px',
        backgroundColor: theme.palette.primary.wine,
        color: 'white',
        textAlign: 'center',
        bottom: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Grid
        container
        spacing={2}
        flexDirection={{ xs: 'column', sm: 'row' }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
        }}

      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="https://cdn.hidalgo.gob.mx/escudo_blanco.svg"
            alt="logo"
            style={{ width: 'auto', height: '100px' }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            src="https://cdn.hidalgo.gob.mx/escudo_blanco.svg"
            alt="logo"
            style={{ width: 'auto', height: '100px' }}
          />
          <Typography variant="body2">
            © {new Date().getFullYear()} Gobierno del Estado de Hidalgo
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingBottom: xs => xs ? 0 : 2,
          }}
        >
          <Typography
            variant="h6"
            align="center"
          >
            Contacto
          </Typography>
          <Typography
            variant="body2"
            align="center"
          >
            Blvd. Camino a Téllez km. 1, Jagüey de Téllez Zempoala, Hidalgo, C.P. 43840
          </Typography>
          <Typography
            variant="body2"
            align="center"
          >
            771 133 3602
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}