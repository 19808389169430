import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { store } from './context/redux/store';
import { Provider } from 'react-redux';
import { APIProvider } from '@vis.gl/react-google-maps';
import KonamiCode from './components/Tour/konami-code';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <CssBaseline />
        <App />
        <KonamiCode />
      </APIProvider>
    </ThemeProvider>
  </Provider>
);