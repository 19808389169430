import React from "react";

import Bannner from "../sections/CaminoSeguro/Bannner";

const CaminoSeguro = () => {
  return(
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '54px',
      }}
    >
        <Bannner />
    </div>
  )
};

export default CaminoSeguro;