import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Divider, Typography, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContentText, DialogActions, Tooltip, DialogContent } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Mapa } from '../Mapa/Mapa';
import { useTheme } from '@emotion/react';
import { Toaster } from 'react-hot-toast';
import { rutasTroncales, rutasAlimentadoras } from '../../constant/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import CustomScrollbar from '../ScrollBar/CustomScrollbar';
import TroncalesIcon from '../../icons/troncalesIcon';
import PuntosRecarga from '../../icons/puntosRecargaIcon';
import AlimentadorasIcon from '../../icons/alimentadorasIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CancelIcon from '@mui/icons-material/Cancel';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { changedTroncalActive } from '../../context/redux/slicers/troncalesSlice';
import { changedRutasActivas } from '../../context/redux/slicers/rutasActivasSlice';
import { changedAlimentadoraActive } from '../../context/redux/slicers/alimentadorasSlice';
import Tour from '../Tour/Tour';
import ModalRecorrido from '../Modals/ModalRecorrido';
import ModalStep3 from '../Modals/ModalStep3';

const CustomDrawer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openMenuItemTroncal, setOpenMenuItemTroncal] = useState(false);
  const [openMenuItemAlimentadora, setOpenMenuItemAlimentadora] = useState(false);
  const [rutaTroncalSeleccionada, setRutaTroncalSeleccionada] = useState(null);
  const [rutaAlimentadoraSeleccionada, setRutaAlimentadoraSeleccionada] = useState(null);
  //TODO recorrido guiado 
  const [modalRecorrido, setModalRecorrido] = useState(false);
  const [mostrarRecorrido, setMostrarRecorrido] = useState(false);
  //TODO recorrido guiado
  const [step, setStep] = useState(0);
  const [cambioPaso, setCambioPaso] = useState();
  const [rutasActivas, setRutasActivas] = useState([
    {
      ruta: 'troncal',
      active: false,
    },
    {
      ruta: 'alimentadora',
      active: false,
    },
    {
      ruta: 'puntosRecarga',
      active: false,
    },
  ]);

  const iconArrow = openMenuItemTroncal ?
    <KeyboardArrowUpIcon sx={{ color: theme.palette.primary.gold }} /> :
    <KeyboardArrowDownIcon sx={{ color: theme.palette.primary.white }} />;
  const iconArrowAlimentadora = openMenuItemAlimentadora ?
    <KeyboardArrowUpIcon sx={{ color: theme.palette.primary.gold }} /> :
    <KeyboardArrowDownIcon sx={{ color: theme.palette.primary.white }} />;
  const drawerWidth = 250;

  // Constante para saber si es una pantalla extra pequeña
  const isXs = theme.breakpoints.values.sm > 600;

  //useEffect para cambiar las ruta troncal activa
  useEffect(() => {
    dispatch(changedTroncalActive({
      rutaId: rutaTroncalSeleccionada,
      color: rutasTroncales?.find((ruta) => ruta?.id === rutaTroncalSeleccionada)?.color,
    }));
  }, [rutaTroncalSeleccionada]);

  //useEffect para cambiar la ruta alimentadora activa
  useEffect(() => {
    dispatch(changedAlimentadoraActive(rutaAlimentadoraSeleccionada));
  }, [rutaAlimentadoraSeleccionada]);

  //useEffect tipo swtich para activar desactivar rutas visibles
  useEffect(() => {
    const rutasSelected = []
    rutasActivas.map((ruta) => {
      if (ruta.active) {
        rutasSelected.push(ruta.ruta);
      }
    });
    dispatch(changedRutasActivas(rutasSelected));
  }, [rutasActivas]);

  // Acciones a realizar cuando se cambia de paso en el tour
  useEffect(() => {
    AllSteps();
  }, [step]);

  useEffect(() => {
    getMostrarRecorridoLocal();
  }, []);

  //useEffect para cambiar los valores del redux de las rutas seleccionadas
  useEffect(() => {
    const rutasSelected = []
    rutasActivas.map((ruta) => {
      if (ruta.active) {
        rutasSelected.push(ruta.ruta);
      }
    });
    dispatch(changedRutasActivas(rutasSelected));
  }, []);

  const AllSteps = () => {
    switch (step) {
      case 1:
        //Abrir menu lateral
        setOpen(true);
        break;
      case 2:
        //Activar la ruta troncal
        setRutasActivas([
          {
            ruta: 'troncal',
            active: true,
          },
          {
            ruta: 'puntosRecarga',
            active: false,
          },
          {
            ruta: 'alimentadora',
            active: false,
          },
        ]);
        setOpenMenuItemTroncal(true);
        //setRutaTroncalSeleccionada('T-01');
        break;
      case 3:
        // Desactivar la ruta troncal y activar la alimentadora
        setOpenMenuItemTroncal(false);
        setRutaTroncalSeleccionada(null)
        setRutasActivas([
          {
            ruta: 'alimentadora',
            active: true,
          },
          {
            ruta: 'troncal',
            active: false,
          },
          {
            ruta: 'puntosRecarga',
            active: false,
          },
        ]);
        //setOpenMenuItemAlimentadora(true);
        break;
      case 4:
        //Desactivar la ruta alimentadora y activar los puntos de recarga
        setRutaAlimentadoraSeleccionada(null);
        setOpenMenuItemAlimentadora(false);
        setOpenMenuItemTroncal(false);
        setRutasActivas([
          {
            ruta: 'puntosRecarga',
            active: true,
          },
          {
            ruta: 'troncal',
            active: false,
          },
          {
            ruta: 'alimentadora',
            active: false,
          },
        ]);
        window.scrollTo(0, 0);
        break;
      case 5:
        //Desactivar todas las rutas
        setRutasActivas([
          {
            ruta: 'troncal',
            active: false,
          },
          {
            ruta: 'alimentadora',
            active: false,
          },
          {
            ruta: 'puntosRecarga',
            active: false,
          },
        ]);
        setOpenMenuItemTroncal(false);
        setOpenMenuItemAlimentadora(false);
        setOpen(false);
        break;
        case 6:
          //Desactivar todas las rutas
          console.log('paso 6');
          break;

      default:
        break;
    }
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  }

  //Funcion para activar y desactivar las rutas activas
  const handleActiveRuta = (rutaId) => {
    const rutas = rutasActivas.map((ruta) => {
      if (ruta.ruta === rutaId) {
        return {
          ...ruta,
          active: !ruta.active,
        }
      }
      return ruta;
    });
    setRutasActivas(rutas);
  }

  //Funcion switch para saber que ruta se activo/desactivo
  const handleClickedRuta = (rutaId) => {
    setOpen(true);
    switch (rutaId) {
      case 'troncal':
        setOpenMenuItemTroncal(!openMenuItemTroncal);
        handleActiveRuta(rutaId);
        break;
      case 'alimentadora':
        setOpenMenuItemAlimentadora(!openMenuItemAlimentadora);
        handleActiveRuta(rutaId);
        break;
      case 'puntosRecarga':
        handleActiveRuta(rutaId);
        handleDialog();
        break;
      default:
        break;
    }
  }

  //Funcion para cambiar el color del icono activo
  const activeIconColor = (rutaId) => {
    const isActive = rutasActivas.find((ruta) => ruta.ruta === rutaId)?.active;
    return isActive ? theme.palette.primary.gold : theme.palette.primary.white;
  }

  const handleStepChange = (step) => {
    setCambioPaso(step);
    setStep(step);
  };

  //Funcion para buscar en el localstorage el valor de mostrarRecorrido
  const getMostrarRecorridoLocal = () => {
    const mostrarRecorrido = localStorage.getItem('mostrarRecorrido');
    if (mostrarRecorrido === null) {
      setModalRecorrido(true);
    }

  }

  //Funcion para aceptar el recorrido
  const handleRecorridoAceptar = () => {
    setModalRecorrido(false);
    setMostrarRecorrido(true);
    localStorage.setItem('mostrarRecorrido', true);
  }

  // Eliminar la variable mostrarRecorrido del localstorage e iniciar el recorrido
  const handleRecorridoReiniciar = () => {
    // Limpiar los valores del mapa y el recorrido
    setRutasActivas([
      {
        ruta: 'troncal',
        active: false,
      },
      {
        ruta: 'alimentadora',
        active: false,
      },
      {
        ruta: 'puntosRecarga',
        active: false,
      },
    ]);

    setRutaTroncalSeleccionada(null);
    setRutaAlimentadoraSeleccionada(null);
    setOpenMenuItemTroncal(false);
    setOpenMenuItemAlimentadora(false);
    setOpen(false);

    localStorage.removeItem('mostrarRecorrido');
    setModalRecorrido(true);
  }

  const handleRecorridoRechazar = () => {
    setModalRecorrido(false);
    setMostrarRecorrido(false);
    localStorage.setItem('mostrarRecorrido', false);
  }

  const handleCerrarMenu = () => {
    setOpen(false);
  }

  return (
    <Box display="flex">
      {modalRecorrido &&
        <ModalRecorrido
          handleRecorridoAceptar={handleRecorridoAceptar}
          handleRecorridoRechazar={handleRecorridoRechazar}
          open={modalRecorrido}
          onClose={() => setModalRecorrido(false)}
        />
      }
      {mostrarRecorrido && <Tour onStepChanged={handleStepChange} />}
      <Toaster />
      <Box
        className='step1'
        sx={{
          width: open ? 280 : 60,
          transition: 'width 0.3s',
          bgcolor: 'primary.wine',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: open ? 'none' : 'center',
          p: 1,
        }}
      >
        <CustomScrollbar
          className='step2'
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: open ? 'space-between' : 'center',
              alignItems: 'center',
            }}
          >
            {
              open &&
              <img
                src={"img/logos/Logo_sitmah.svg"}
                style={{
                  width: 'auto',
                  height: '30px',
                  cursor: 'pointer',
                  marginLeft: 20,
                }}
                alt="Logo sitmah"
              />
            }
            <IconButton
              onClick={handleToggle}
              sx={{
                color: open ? theme.palette.primary.gold : theme.palette.primary.white,
              }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          <Divider />
          <List>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                borderBottom: open ? `1px solid ${theme.palette.primary.gold}` : 'none',
              }}
              onClick={() => handleClickedRuta('troncal')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: activeIconColor('troncal'),
                }}
                onClick={() => handleClickedRuta('troncal')}
              >
                <TroncalesIcon color={activeIconColor('troncal')} />
              </ListItemIcon>
              {
                open &&
                <ListItemText
                  primary='Rutas Troncales'
                  sx={{
                    opacity: open ? 1 : 0,
                    color: activeIconColor('troncal'),
                  }}
                />
              }
              {
                open && iconArrow
              }
            </ListItemButton>
            {
              open &&
              <Collapse
                in={openMenuItemTroncal}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                >
                  {rutasTroncales.map((ruta) => (
                    <ListItemButton
                      key={ruta.id}
                      sx={{
                        pl: 2,
                        height: 30,
                      }}
                    >
                      <ListItemIcon
                      >
                        <Button
                          sx={{
                            color: ruta.color,
                            backgroundColor: rutaTroncalSeleccionada === ruta.id ? theme.palette.primary.gold : 'transparent',
                            width: drawerWidth - 50,
                            pl: 5,
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() => {
                            rutaTroncalSeleccionada === ruta.id ? setRutaTroncalSeleccionada(null) : setRutaTroncalSeleccionada(ruta.id);
                          }}
                        >
                          {
                            rutaTroncalSeleccionada === ruta.id ?
                              <Brightness1Icon
                                sx={{
                                  color: ruta.color,
                                  fontSize: 16,
                                }}
                              /> :
                              <RadioButtonCheckedIcon
                                sx={{
                                  color: ruta.color,
                                  fontSize: 16,
                                }}
                              />
                          }
                          <Typography variant="body2" color={theme.palette.primary.white} sx={{ ml: 2 }}>
                            {ruta.nombre}
                          </Typography>
                        </Button>
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            }

            <ListItemButton
              className='step3'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                borderBottom: open ? `1px solid ${theme.palette.primary.gold}` : 'none',
              }}
              onClick={() => handleClickedRuta('alimentadora')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: activeIconColor('alimentadora'),
                }}
                onClick={() => handleClickedRuta('alimentadora')}
              >
                <AlimentadorasIcon color={activeIconColor('alimentadora')} />
              </ListItemIcon>
              {
                open &&
                <ListItemText
                  primary='Rutas Alimentadoras'
                  sx={{
                    opacity: open ? 1 : 0,
                    color: activeIconColor('alimentadora'),
                  }}
                />
              }
              {
                open && iconArrowAlimentadora
              }
            </ListItemButton>
            {
              open &&
              <Collapse
                in={openMenuItemAlimentadora}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                >
                  {rutasAlimentadoras.map((ruta) => (
                    <ListItemButton
                      key={ruta.id}
                      sx={{
                        display: 'flex',
                        height: 'auto',
                        pl: 2,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          display: 'flex',
                          height: 'auto',
                        }}
                      >
                        <Tooltip title={ruta.nombre} placement={isXs ? 'right' : 'top'}>
                          <Button
                            sx={{
                              height: 'auto',
                              color: ruta.color,
                              width: drawerWidth - 50,
                              pl: 5,
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                            onClick={() => {
                              rutaAlimentadoraSeleccionada === ruta.id ? setRutaAlimentadoraSeleccionada(null) : setRutaAlimentadoraSeleccionada(ruta.id);
                            }}
                          >
                            {
                              rutaAlimentadoraSeleccionada === ruta.id ?
                                <Brightness1Icon
                                  sx={{
                                    color: ruta.color,
                                    fontSize: 16,
                                  }}
                                /> :
                                <RadioButtonCheckedIcon
                                  sx={{
                                    color: ruta.color,
                                    fontSize: 16,
                                  }}
                                />
                            }
                            <Typography variant="body2" color={theme.palette.primary.white} sx={{ ml: 2 }}>
                              {ruta.id}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            }


            <ListItemButton
              className='step4'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                borderBottom: open ? `1px solid ${theme.palette.primary.gold}` : 'none',
              }}
              onClick={() => handleClickedRuta('puntosRecarga')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: activeIconColor('puntosRecarga'),
                }}
                onClick={() => handleClickedRuta('puntosRecarga')}
              >

                <PuntosRecarga color={activeIconColor('puntosRecarga')} />
              </ListItemIcon>
              {
                open &&
                <ListItemText
                  primary='Puntos de Recarga'
                  sx={{
                    opacity: open ? 1 : 0,
                    color: activeIconColor('puntosRecarga'),
                  }}
                />
              }
            </ListItemButton>
          </List>


          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <Tooltip title="Volver a hacer el recorrido" placement="right">
              <IconButton
                onClick={handleRecorridoReiniciar}
                sx={{
                  color: theme.palette.primary.gold,
                }}
              >
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            {
              open &&
              <Typography
                sx={{
                  color: theme.palette.primary.gold,
                  fontSize: 12,
                }}
              >
                Ayuda
              </Typography>
            }
          </Box>

        </CustomScrollbar>
      </Box>
      <Box
        flexGrow={1}
      >
        <Mapa
          handleCerrarMenu={handleCerrarMenu}
        />
      </Box>
      <Dialog
        open={openDialog && rutasActivas.find((ruta) => ruta.ruta === 'puntosRecarga').active}
        onClose={handleDialog}
        fullWidth
        maxWidth="md"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: 10,
            padding: {
              xs: 0,
              md: 5,
            }
          },
        }}
      >
        <DialogTitle
          sx={{
            color: 'primary.gold',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              variant: `h${isXs ? 5 : 4}`,
            }}
          >
            ¿QUÉ SON LOS PUNTOS DE RECARGA?
          </Typography>
          <IconButton
            onClick={handleDialog}
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: 'primary.wine',
            }}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContentText
          sx={{
            px: 2,
            pb: 5,
            color: 'primary.black',
            textAlign: 'center',
            variant: {
              xs: 'body2',
              md: 'body1',
            }
          }}
        >
          Sitios que facilictan a las personas usuarias la recarga de su tarjeta antes de subir al transporte al poder hacerlo en algún pequeño negocio cercano a su casa, principalmente a aquellas que viven en lugares lejanos a las estaciones, donde se encuentran las máquinas de recarga.
          No se deje sorprender, revise los puntos de recarga externa autorizados
          Para ofrecer una experiencia más completa y conveniente a las personas usuarias, resulta fundamental mostrar en un mapa la ubicación exacta de los puntos de recarga autorizados por nuestro organismo.
          Esta herramienta permitirá a las personas usuarias localizar de manera rápida y sencilla el lugar más cercano para recargar sus tarjetas.
          Además, para garantizar la total transparencia y confianza, es recomendable incluir una fotografía del lugar, proporcionando así una vista previa del entorno y facilitando su identificación.
          De esta manera, se promueve la utilización consciente de los puntos de recarga, contribuyendo a la eficiencia y comodidad de este servicio.
        </DialogContentText>
      </Dialog>
    </Box>
  );
};

export default CustomDrawer;
