import React from 'react';
import { Grid, List, ListItem, ListItemText } from "@mui/material";

import { TitlePage } from "../../components/Title/TitlePage";

export const Equidad = () => {

    return (
        <>
            <TitlePage title={"EQUIDAD DE GÉNERO"} />
            <Grid container
                sx={{
                    padding: {
                        md: '0px 80px',
                        sm: '0px 20px',
                        xs: '10px'
                    }
                }}
            >
                <Grid item
                    style={{
                        backgroundImage: "url(/img/piramide/equidad.png)",
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '400px'
                    }}
                    sx={{
                        backgroundSize: {
                            md: 'contain',
                            xs: 'cover'
                        }
                    }}
                />
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem
                            style={{
                                textAlign: 'justify',
                                flexDirection: 'column'
                            }}
                        >
                            <ListItemText>
                                Las mujeres enfrentan problemáticas específicas que limitan sus posibilidades de desplazarse
                                con seguridad, libertad y comodidad.
                            </ListItemText>
                            <ListItemText>
                                Las mujeres realizan más viajes días en el transporte público que los hombres, sin embargo,
                                la desigualdad entre hombres y mujeres provoca cotidianamente situaciones de discriminación
                                y violencia contra ellas que tienen impacto en su salud, vida pública y capacidad de acceso
                                y, por tanto, en el desarrollo del país.
                                La Secretaría de Movilidad y Transporte del Estado de Hidalgo a través de su organismo el
                                Sistema Integrado de Transporte Masivo de Hidalgo, reconoce el grave problema que
                                representa la violencia contra las mujeres en espacios públicos.
                            </ListItemText>
                            <ListItemText>
                                Alineada a la política pública con perspectiva de género impulsada por el gobernador Julio
                                Menchaca Salazar, fortalece los protocolos de actuación ante casos de emergencia para
                                priorizar la seguridad de las personas usuarias, en especial del sector femenino, que
                                representa el 54 por ciento del público total.
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem
                            style={{
                                textAlign: 'justify',
                                flexDirection: 'column'
                            }}
                        >
                            <ListItemText>
                                Para garantizar el derecho humano a la movilidad y con base en lo establecido en la Ley de 
                                Acceso de las Mujeres a una Vida Libre de Violencia para el Estado de Hidalgo, el Sitmah 
                                cuenta con el código naranja en las 34 estaciones del Tuzobús mediante el cual, usuarias y 
                                no usuarias del sistema pueden solicitar ayuda en caso de ser víctimas de acoso, violencia, 
                                discriminación, desigualdad, explotación, abuso sexual, entre otros.
                            </ListItemText>
                            <ListItemText>
                                Ante estos casos, en coordinación interinstitucional, la Policía Industrial Bancaria (Pibeh), 
                                el Centro General de Gestión de Operaciones (CGGO) del Sistema Tuzobús, la Secretaría de 
                                Seguridad Pública del Estado de Hidalgo (Sspeh) a través del Centro de Control, Comando, 
                                Comunicaciones, Cómputo, Coordinación e Inteligencia (C5i) Hidalgo y diversos ayuntamientos, 
                                dan seguimiento y acompañamiento a las víctimas.
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );


};