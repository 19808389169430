import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import PropTypes from 'prop-types';

const Tour = (props) => {
  const theme = useTheme();
  const { onStepChanged } = props;
  const [run, setRun] = useState(true); // El recorrido se inicia automáticamente
  const [stepIndex, setStepIndex] = useState(0);

  const styleSteps = {
    options: {
      backgroundColor: theme.palette.primary.white,
      textColor: theme.palette.primary.black,
      arrowColor: theme.palette.primary.white,
      width: 400,
      borderRadius: 10,
    }
  };

  const steps = [
    {
      target: '.step1',
      content: 'La nueva página web de Tuzobús cuenta con un mapa interactivo y menús que permiten visualizar las diversas rutas, tanto troncal como de las rutas alimentadoras.',
      styles: styleSteps,
      placement: 'right',
      floaterProps: {
        disableAnimation: true,
      },
      disableScrolling: true,
    },
    {
      target: '.step2',
      content: 'Puedes seleccionar que ruta ver en el mapa, además de activar o desactivar las estaciones y rutas de la línea troncal.',
      styles: styleSteps,
      placement: 'right',
      floaterProps: {
        disableAnimation: false,
      },
      disableScrolling: true,
    },
    {
      target: '.step3',
      content: 'También tienes la posibilidad de seleccionar la ruta alimentadora de tu preferencia para visualizar el recorrido.',
      styles: styleSteps,
      placement: 'right',
      floaterProps: {
        disableAnimation: false,
      },
      disableScrolling: true,
    },
    {
      target: '.step4',
      content: 'Por último podrás consultar la ubicación de los puntos de recarga externos para tu tarjeta Tuzobús.',
      styles: styleSteps,
      placement: 'right',
      floaterProps: {
        disableAnimation: false,
      },
      disableScrolling: true,
    },
    {
      target: '.step5',
      content: 'También puedes encontrar secciones informativas en el menú de Beneficios.',
      styles: styleSteps,
      placement: 'bottom',
      floaterProps: {
        disableAnimation: true,
      },
      disableScrolling: true,
    },
  ];

  useEffect(() => {
    if (onStepChanged) {
      onStepChanged(stepIndex);
    }
  }, [stepIndex, onStepChanged]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {

      //Pasar al step2
      if (index === 0 && action === ACTIONS.NEXT) {
        onStepChanged(1);
        // Añadir un retraso de 1 segundo antes de cambiar al siguiente paso
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 1000);
      } else {
        setStepIndex(index + (action === ACTIONS.NEXT ? 1 : -1));
      }

      //Pasar al step3
      if (index === 1 && action === ACTIONS.NEXT) {
        onStepChanged(2);
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 1000);
      }
      //Pasar al step4
      if (index === 2 && action === ACTIONS.NEXT) {
        onStepChanged(3);
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 1000);
      }
      //Pasar al step5
      if (index === 3 && action === ACTIONS.NEXT) {
        onStepChanged(4);
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 1000);
      }
      //Pasar al step6
      if (index === 4 && action === ACTIONS.NEXT) {
        console.log('step5');
        onStepChanged(5);
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 1000);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setStepIndex(0);
      // Si deseas reiniciar el recorrido automáticamente después de que termine:
      // setTimeout(() => setRun(true), 1000); // Reinicia después de 1 segundo
    }


    //Detectar cuando se termina el recorrido
    if (status === STATUS.FINISHED) {
      //Recargar la página
      window.location.reload();
    }
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={stepIndex}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      scrollToFirstStep={false}
      locale={{
        back: 'Atrás',
        close: 'Cerrar',
        last: 'Último',
        next: 'Siguiente',
        skip: 'Salir del recorrido',
      }}
      styles={{
        tooltipContent: {
          //padding: 0,
          textAlign: 'left',
          borderRadius: 10,
        },
        buttonNext: {
          backgroundColor: theme.palette.primary.white,
          color: theme.palette.primary.gold,
          border: `2px solid ${theme.palette.primary.gold}`,
          borderRadius: 10
        },
        buttonBack: {
          marginRight: 10,
          color: theme.palette.primary.white,
        },
        buttonSkip: {
          color: theme.palette.primary.wine,
          // Ponerle subrayado al botón de skip
          textDecoration: 'underline',
        },
        options: {
          primaryColor: theme.palette.primary.gold,
          textColor: '#004a14',
          width: 900,
          zIndex: 1000,
        },
      }}
    />
  );
};

export default Tour;

Tour.propTypes = {
  onStepChanged: PropTypes.func,
};
