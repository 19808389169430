import React from "react";

import { Codes } from "../sections/CodigoTuzobus/Codes";
import { Protocolos } from "../sections/CodigoTuzobus/Protocolos";

const CodigoTuzobus = () => {
    return (
        <>
            <Codes />
            <Protocolos />
        </>
    )
};

export default CodigoTuzobus;