import React from 'react';
import { Box, Portal, ClickAwayListener } from '@mui/material';
import { useTheme } from '@emotion/react';

export const CustomPopover = ({ anchorEl, open, onClose, children }) => {
  const theme = useTheme();
  if (!open || !anchorEl) return null;

  const { top, left, height } = anchorEl.getBoundingClientRect();
  const popoverStyle = {
    position: 'absolute',
    top: top + height + window.scrollY,
    left: left + window.scrollX,
    bgcolor: 'background.paper',
    boxShadow: 3,
    zIndex: 1300,
    border: `2px solid ${theme.palette.primary.wine}`,
    borderRadius: 5,
    with: '500px'
  };

  return (
    <Portal>
      <ClickAwayListener onClickAway={onClose}>
        <Box sx={popoverStyle}>
          {children}
        </Box>
      </ClickAwayListener>
    </Portal>
  );
};
