import React from 'react';
import { AdultoMayor } from '../sections/Tarifas/AdultoMayor';
import { TitlePage } from '../components/Title/TitlePage';
import { Discapacitado } from '../sections/Tarifas/Discapacitado';


const TarifasPreferenciales = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '66px',
        marginBottom: '50px',
      }}
    >
      <TitlePage title="TARIFAS PREFERENTES" />
      <AdultoMayor />
      <Discapacitado />
    </div>
  );
}

export default TarifasPreferenciales;