import React from 'react';
import { Drawer, Box, Typography, Grid, Divider, Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { troncalesData } from '../../utils/troncalesData';
import CustomScrollbar from '../ScrollBar/CustomScrollbar';
import MasonryImageList from '../ImageList/MasonryImageList';
import { formatDateTime } from '../../utils/formats';

const elevadorIcon = 'img/icons/elevadorIcon.svg';
const puenteIcon = 'img/icons/puenteIcon.svg';

const DividerCustom = () => (
  <Divider sx={{ width: '90%', mt: 2 }} />
);

const DrawerEstacion = ({ open, onClose, marker }) => {
  const theme = useTheme();
  const now = formatDateTime(new Date());

    const calcularConcurrencia = (aglomeracion) => {
      const horaActual = new Date().getHours();

      const aglomeracionActual = aglomeracion?.find((aglomeracion) => {
        const horaInicio = parseInt(aglomeracion?.hora.split('-')[0].split(':')[0]);
        const horaFin = parseInt(aglomeracion?.hora.split('-')[1].split(':')[0]);
        return horaActual >= horaInicio && horaActual < horaFin;
      });
      return aglomeracionActual?.nivel || 'Bajo';
    }; 

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: {
            xs: 300,
            md: 350
          },
          p: 2,
        }}
      >
        <CustomScrollbar>
          <Grid
            container
            spacing={2}
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <img
                src={marker?.estatus ? marker?.imagen_on : marker?.imagen_off}
                alt='logo'
                style={{ width: 'auto', height: '90px' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  pl: 2,
                }}
              >
                <Typography
                  variant='h6'
                  color='primary.text.primary'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {marker?.nombre}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      width: {
                        xs: '80px',
                        md: 'auto',
                      },
                      borderRadius: 5,
                      px: {
                        md: 1,
                      },
                      backgroundColor: marker?.estatus ? theme.palette.green.secondary : theme.palette.primary.wine,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        width: 'auto',
                        color: marker?.estatus ? theme.palette.green.contrastText : theme.palette.primary.wine,
                      }}
                    >
                      {marker?.estatus ? 'Abierta' : 'Cerrada'}
                    </Typography>
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <DividerCustom />
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <Typography
                variant='subtitle1'
                color='primary.text.primary'
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                RUTAS TRONCALES
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '100%',
                  borderRadius: 5,
                  mt: 1,
                }}
              >
                {
                  marker?.servicios_troncal.map((servicio, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1,
                        borderRadius: 5,
                        backgroundColor: troncalesData(servicio, theme).color,
                      }}
                    >
                      <Typography
                        variant='caption'
                        color='primary.white'
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {troncalesData(servicio, theme).shortName}
                      </Typography>
                    </Box>
                  ))
                }
              </Box>
            </Grid>
            <DividerCustom />
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={'img/icons/horarioIcon.svg'}
                  alt='Horario'
                  style={{ width: 'auto', height: '50px' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary.text.primary'
                    sx={{
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    HORARIO DE SERVICIO:
                  </Typography>
                  <Typography
                    variant='caption'
                    color='primary.text.primary'
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    {marker?.horario}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {
              (marker?.puente || marker?.elevador) && (
                <>
                  <DividerCustom />
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      color='primary.text.primary'
                      sx={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                      }}
                    >
                      ACCESO PARA DISCAPACITADOS
                    </Typography>
                  </Grid>
                </>
              )
            }

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {
                marker?.puente && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <img
                      src={puenteIcon}
                      alt='Puente'
                      style={{ width: 'auto', height: '48px' }}
                    />
                    <Typography
                      variant='subtitle2'
                      color='primary.text.primary'
                      sx={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        ml: 1,
                      }}
                    >
                      PUENTE
                    </Typography>
                  </Box>
                )
              }
              {
                marker?.elevador && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <img
                      src={elevadorIcon}
                      alt='Elevador'
                      style={{ width: 'auto', height: '48px' }}
                    />
                    <Typography
                      variant='subtitle2'
                      color='primary.text.primary'
                      sx={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        ml: 1,
                      }}
                    >
                      ELEVADOR
                    </Typography>
                  </Box>
                )
              }


            </Grid>


            <DividerCustom />
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Typography
                variant='subtitle1'
                color='primary.text.primary'
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                CONCURRENCIA
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
              }}
            >

              <Typography
                variant='caption'
                color={theme.palette.text.light}
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                HORA ACTUAL
              </Typography>
              <Typography
                variant='caption'
                color={theme.palette.text.light}
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                {now}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
                height: '100%',
              }}
            >
              <Chip
                label={calcularConcurrencia(marker?.aglomeracion)}
                color={calcularConcurrencia(marker?.aglomeracion) === 'Alto' ? 'error' : calcularConcurrencia(marker?.aglomeracion) === 'Medio' ? 'warning' : 'success'}
                sx={{
                  borderRadius: 5,
                  mt: 1,
                }}
              />
              </Box>
          </Grid>
          <DividerCustom />
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <MasonryImageList itemData={marker?.fotos_referencia} />
          </Grid>
        </CustomScrollbar>
      </Box>
    </Drawer>
  );
}

export default DrawerEstacion;
