import React from 'react';
import Footer from './components/Footer/Footer';
import Router from './Router';
import { useTheme } from '@emotion/react';
import KonamiCode from './components/Tour/konami-code';

export default function App() {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.white,
      }}
    >
      <Router />
      <Footer />
      <KonamiCode />
    </div>
  );
}
