import React from "react";
import { Grid, List, ListItem, ListItemText, Stack, Avatar, Box } from "@mui/material";

import { TitlePage } from "../../components/Title/TitlePage";

const Bannner = () => {
  return (
    <>
      <TitlePage title={"CAMINO SEGURO"} />
      <Grid container
        style={{
          marginTop: '50px'
        }}
        sx={{
          padding: {
            md: '0px 80px',
            sm: '0px 20px',
            xs: '10px'
          }
        }}
      >
        <Grid item
          style={{
            backgroundImage: "url('/img/codigos/Banner_camino_seguro.png')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '400px',
            marginBottom: '50px',
            display: 'flex',
          }}
          xs={12}
          sx={{
            backgroundPosition: {
              md: 'center'
            },
            backgroundPositionX: {
              xs: '-100px'
            },
            justifyContent: {
              md: 'flex-end'
            },
            alignItems: {
              md: 'center',
              xs: 'flex-end'
            },
            color: {
              xs: "primary.white"
            }
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              background: {
                md: 'transparent',
                xs: 'linear-gradient(to bottom, transparent 30%, #504F4F);'
              },
              backgroundPosition: {
                md: 'center'
              },
              backgroundPositionX: {
                xs: '-100px'
              },
              justifyContent: {
                md: 'center',
                xs: 'end'
              },
              alignItems: {
                md: 'flex-end',
                xs: 'flex-end'
              }
            }}
          >
            <Box
              sx={{
                width: {
                  md: '50%',
                  sx: '100%'
                },
                color: {
                  md: 'black',
                  xs: "primary.white"
                },
                textAlign: {
                  xs: 'justify',
                  md: 'justify'
                },
                padding: {
                  xs: '10px 20px'
                }
              }}
            >
              El Tuzobús es un sistema de transporte urbano innovador, en continua tranformación y mejora para las personas usuarias. Conócelo a tráves de los 9 puntos.
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} >
          <List
            sx={{ width: '100%' }}
          >
            <ListItem
              style={{
                textAlign: 'justify',
                flexDirection: 'column'
              }}
            >
              <ListItemText>
                1. Tuzobús pone a su disposición del usuario autobuses de 12 metros para 80 personas en la Troncal, mientras que en las rutas alimentadoras se cuenta con unidades de 8 metros para 50 personas y vagonetas para 19 computadoras.
              </ListItemText>
              <ListItemText>
                2. Ofrece un sistema de cobro a través de la Tarjeta Inteligente sin Contacto, para que la personas usuario pague con la misma y obtenga los beneficios dentro del sistema.
              </ListItemText>
              <ListItemText>
                3. Al viajar en el Tuzobús, la persona usuaria tiene acceso a un seguro de viajero. Este seguro te protege en las estaciones y durante tu trayecto en cualquiera de las unidades, ya sea en la ruta Troncal o en las alimentadoras.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}
          style={{
            display: 'flex',
            minHeight: '350px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/*}
                <Stack
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url('/img/codigos/imgCS1.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '350px'
                    }}
                />
                */}
          <Avatar src="/img/codigos/imgCS1.png" alt=""
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '0px'
            }}
            sx={{
              padding: {
                xs: '25px 0px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}
          style={{
            display: 'flex',
            minHeight: '350px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/*}
                <Stack
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url('/img/codigos/imgCS3.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '350px'
                    }}
                />
                */}
          <Avatar src="/img/codigos/imgCS3.png" alt=""
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '0px'
            }}
            sx={{
              padding: {
                xs: '25px 0px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            sx={{ width: '100%' }}
          >
            <ListItem
              style={{
                textAlign: 'justify',
                flexDirection: 'column'
              }}
            >
              <ListItemText>
                4. Una misma tarjeta con sufuciente saldo, puede ser utilizada para pagar los pasajes de más de una persona.
              </ListItemText>
              <ListItemText>
                5. Al adquirir tu Tarjeta Tuzobús, recibirás una reducción de los precios de traslado y transbordo (alimentadora-troncal-alimentadora): primer viaje $10 pesos; primer y segundo transbordos serán de $0 persos.
              </ListItemText>
              <ListItemText>
                6. Si el saldo de tu Tarjeta Tuzobús es insuficiente, pero cuentas con $1 peso como saldo minimo, el sistema te permite efectuar un viaje a crédito, que se te descontará de la tarjeta en tu siguiente recarga.
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List
            sx={{ width: '100%' }}
          >
            <ListItem
              style={{
                textAlign: 'justify',
                flexDirection: 'column'
              }}
            >
              <ListItemText>
                7. La Tarjeta Tuzobús no caduca (siempre y cuando no sea tarjeta preferente), basta con hacerle una recarga y se vuelve a activar.
              </ListItemText>
              <ListItemText>
                8. Tuzobús ofrece tarifas preferenciales del 50% de descuento para adultos mayores y personas con discapacidad permanente.
              </ListItemText>
              <ListItemText>
              9. El Tuzobús es un transporte seguro, ya que cuenta con la vigilancia de 522 cámaras. Además, las estaciones de la ruta Troncal son monitoreadas a través del Centro General de Gestión  de Operaciones, que está en contacto con las autoridades para notificar cualquier situación riesgosa.
              </ListItemText>
              <ListItemText>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}
          style={{
            display: 'flex',
            minHeight: '350px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          sx={{
            marginBottom: {
              md: '25px'
            }
          }}
        >
          {/*}
                <Stack
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url('/img/codigos/imgCS2.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '350px'
                    }}
                />
                */}
          <Avatar src="/img/codigos/imgCS2.png" alt=""
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '0px'
            }}
            sx={{
              padding: {
                xs: '25px 0px'
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
};

export default Bannner;