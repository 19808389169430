import React from "react";

import HeaderCV from "../sections/CulturaVial/HeaderCV";
import ReglasCV from "../sections/CulturaVial/Reglas";
import TableCV from "../sections/CulturaVial/TableCV";

const CulturaVial = () => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '66px',
                marginBottom: '50px',
            }}
        >
            <HeaderCV />
            <ReglasCV />
            <TableCV />
        </div>
    )
};

export default CulturaVial;