/**
 * @param {Array} alimentadoras
 * @returns {Array} lineasRutasAlimentadoras
 * 
 */

const darFormatoGeoJson = (files) => {
  const filesExtension = []
  //De cada objeto de files, obtener el 
  files.forEach(file => {
    const extension = `geojson/${file}.geojson`
    filesExtension.push(extension)
  })

  return filesExtension
}

export const lineasActiveAlimentadora = (alimentadoras) => {
  const files = []

  //De todas las alimentadoras, obtener todos los array de files
  alimentadoras.forEach(alimentadora => {
    files.push(alimentadora.files)
  })

  //Darle formato a los files
  const lineasRutasAlimentadoras = darFormatoGeoJson(files)

  //Unir todos los files en un solo array
  const lineasRutasAlimentadorasFinal = lineasRutasAlimentadoras.flat()

  return lineasRutasAlimentadorasFinal
}