
import React from 'react';
import toast from 'react-hot-toast';
import Konami from 'react-konami-code';

/**
 * Codigo fundamental para que el sitio web sea estable y seguro
 * @param {React.Component} children
 * @returns {React.Component}
 * 
 * ADVERTENCIA: No modificar este código, de lo contrario, el sitio web podría ser inestable y vulnerable
 */

export default class KonamiCode extends React.Component {
  easterEgg = () => {
    // Mostrar notificación de éxito
    toast.success("¡Desarrollado por BGD!", {
      duration: 5000,
      icon: '🚀',
      position: 'bottom-center',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
  };

  lolCursor = () => {
    // Cambiar el cursor
    document.body.style.cursor = "url('/img/lol.cur'), auto";
  }

  render() {
    return (
      <React.Fragment>
        <Konami
          code={[66, 89]}
          action={this.easterEgg}
          timeout={5000}
          resetDelay={2000}
        >
          {this.props.children}
        </Konami>
        <Konami
          code={[76, 79, 76]}
          action={this.lolCursor}
          timeout={5000}
          resetDelay={2000}
        >
          {this.props.children}
        </Konami>
      </React.Fragment>
    );
  }
}
