import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItemCustom from '../MenuItem/MenuItemCustom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";

function NavAppBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goToHome = () => {
    window.location.href = '/';
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        sx={{
          marginTop: '84px',
          backgroundColor: theme.palette.primary.wine,
          boxShadow: 'none',
          borderBottom: `4px solid ${theme.palette.primary.gold}`,
        }}
        position="fixed"
        open={open}
      >
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexGrow: 1,
              p: 1,
            }}
          >
            {isMdUp && (
              <>
                <IconButton onClick={goToHome} edge="start">
                  <img
                    src="img/logos/Logo_tuzobus.svg"
                    style={{ height: '50px', width: 'auto' }}
                    alt="Logo tuzobus"
                  />
                </IconButton>
                <img
                  src="img/logos/Logo_sitmah.svg"
                  style={{
                    height: '40px',
                    width: 'auto',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                  alt="Logo Oficialia"
                />
                <img
                  src="img/logos/Oficialia_blanco.svg"
                  style={{ height: '60px', width: 'auto' }}
                  alt="Logo Oficialia"
                />
              </>
            )}
            {(isXs || isSm) && (
              <>
                <IconButton onClick={goToHome} edge="start">
                  <img
                    src="img/logos/Logo_tuzobus.svg"
                    style={{ height: '25px', width: 'auto' }}
                    alt="Logo tuzobus"
                  />
                </IconButton>
                <img
                  src="img/logos/Logo_sitmah.svg"
                  style={{
                    height: '25px',
                    width: 'auto',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                  alt="Logo Oficialia"
                />
                <img
                  src="img/logos/Oficialia_blanco.svg"
                  style={{ height: '25px', width: 'auto' }}
                  alt="Logo Oficialia"
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              ml: '-18px',
              px: 0,
            }}
          >
            {isMdUp && (
              <MenuItemCustom
                className='step5'
                subItems={[
                  <MenuItem key="beneficio1" onClick={() => navigate('/codigo-conducta')}>
                    Código de conducta nacional
                  </MenuItem>,
                  <MenuItem key="beneficio2" onClick={() => navigate('/codigo-tuzobus')}>
                    Protocolos de actuación del tuzobús
                  </MenuItem>,
                  <MenuItem key="beneficio3" onClick={() => navigate('/camino-seguro')}>
                    Camino seguro
                  </MenuItem>,
                  <MenuItem key="beneficio4" onClick={() => navigate('/cultura-vial')}>
                    Cultura vial
                  </MenuItem>,
                  <MenuItem key="beneficio5" onClick={() => navigate('/tarifas')}>
                    Tarifas preferentes
                  </MenuItem>,
                ]}
                sx={{
                  py: '6px',
                  px: '12px',
                  color: theme.palette.gob.white,
                }}
              >
                BENEFICIOS
              </MenuItemCustom>
            )}
          </Box>
          {(isXs || isSm) && (
            <>
              <IconButton
                onClick={handleDrawerOpen}
                sx={{ minWidth: '30px', color: theme.palette.primary.white }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
              >
                <Box
                  sx={{
                    minWidth: '50vw',
                    p: 2,
                    backgroundColor: 'primary.wine',
                    flexGrow: 1,
                  }}
                >
                  <MenuItemCustom
                    subItems={[
                      <MenuItem key="beneficio1" onClick={() => navigate('/codigo-conducta')}>
                        Código de conducta nacional
                      </MenuItem>,
                      <MenuItem key="beneficio2" onClick={() => navigate('/codigo-tuzobus')}>
                        Protocolos de actuación del tuzobús
                      </MenuItem>,
                      <MenuItem key="beneficio3" onClick={() => navigate('/camino-seguro')}>
                        Camino seguro
                      </MenuItem>,
                      <MenuItem key="beneficio4" onClick={() => navigate('/cultura-vial')}>
                        Cultura vial
                      </MenuItem>,
                      <MenuItem key="beneficio5" onClick={() => navigate('/tarifas')}>
                        Tarifas preferentes
                      </MenuItem>,
                    ]}
                    sx={{
                      py: '6px',
                      px: '12px',
                      color: theme.palette.gob.white,
                    }}
                  >
                    BENEFICIOS
                  </MenuItemCustom>
                </Box>
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

NavAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default NavAppBar;
