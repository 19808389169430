import React from 'react';
import { TitlePage } from '../components/Title/TitlePage';
import { BannerTuzobus } from '../sections/AppTuzo/BannerTuzo';
import { CononceAppTuzo } from '../sections/AppTuzo/Conoce';


const AppTuzo = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '66px',
        marginBottom: '50px',
        backgroundColor: '#fff',
      }}
    >
      <TitlePage title="APP TUZOBÚS" />
      <BannerTuzobus />
      <CononceAppTuzo />
    </div>
  );
}

export default AppTuzo;