import { configureStore } from "@reduxjs/toolkit";
import rutaTroncalReducer from "./slicers/troncalesSlice";
import rutasActivasReducer from "./slicers/rutasActivasSlice";
import alimentadoraActiveReduce from "./slicers/alimentadorasSlice";

export const store = configureStore({
  reducer: {
    troncalActiva: rutaTroncalReducer,
    rutasActivas: rutasActivasReducer,
    alimentadoraActiva: alimentadoraActiveReduce,
  },
});