import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rutasActivas: ['']
};

export const rutasActivasSlice = createSlice({
  name: "rutasActivas",
  initialState,
  reducers: {
    changedRutasActivas: (state, action) => {
      state.rutasActivas = action.payload;
    }
  }
});

export const { changedRutasActivas } = rutasActivasSlice.actions;
export default rutasActivasSlice.reducer;