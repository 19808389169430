import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export const CononceAppTuzo = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: 'primary.white',
        px: {
          xs: 2,
          md: 10,
        },
        py: 5,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant="h4"
            sx={{
              mt: 10,
              mb: 5,
              color: 'primary.wine',
              textAlign: {
                xs: 'center',
                md: 'left',
              },
              fontWeight: 'bold',
            }}
          >
            ¡Conoce la App de Tuzobús!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: 'primary.textDark',
              textAlign: 'justify',
              mb: 3,
            }}
          >
            En esta aplicación podrás:
            <br /> • Recibe Notificaciones del estado de la red Tuzobús
            <br /> • Visualiza los itinerarios del servicio
            <br /> • Visualiza las unidades por la rama troncal en tiempo real
            <br /> • Visualiza las unidades alimentadoras por ruta
            <br /> • Ubica puntos de recarga externa más cercanos
            <br /> • Entérate de comunicados así como las acciones del transporte masivo Tuzobús, así como comunicados de Gobierno del estado de Hidalgo
            <br /> • Califica el servicio del conductor así como de la unidad
            <br /> • Envíanos sugerencias de mejora
            <br /> • Mantente siempre conectado para brindarte un mejor servicio
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <img
            src="/img/appTuzo/appTuzo.png"
            alt="App Tuzobús"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mt: 5,
            }}
          >
            <img
              src="/img/appTuzo/appStore.png"
              alt="App Store"
              style={{
                width: '150px',
                height: 'auto',
              }}
            />
            <img
              src="/img/appTuzo/googlePlay.png"
              alt="Google Play"
              style={{
                width: '150px',
                height: 'auto',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};