import { React } from "react";
import { Grid, Typography } from '@mui/material';

export const Codes = () => {
  return(
    <>
      <Grid
        container
        spacing={0}
        sx={{
          margin: {
            xs: '100px 0px'
          },
          padding: {
            md: '0px 5%'
          }
        }}
      >
        <Typography
          variant="h4" 
          component="div"
          style={{
            color: '#bc955b',
            borderBottom: '2px solid #bc955b',
            width: '100%',
            padding: '10px',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
          sx={{
            marginBottom: {
              md: '50px'
            },
          }}
        >
          Códigos Tuzobús
        </Typography>
        <Grid item 
          xs={12} 
          md={5}
          style={{
            textAlign: 'center',
            margin: '50px 0px',
            padding: '0px 20px'
          }}
          sx={{
            md: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }
          }}
        >
            <Typography
              variant="h5" 
              component="div"
              style={{
                color: '#691b32',
                fontWeight: 'bold',
                textAlign: 'start',
                marginBottom: '20px'
              }}
            >
                ¿Qué son los protocolos de actuación del tuzobús?
            </Typography>
            <Typography 
            className="description" 
            variant="p" 
            component="div"
            sx={{
              textAlign: {
                xs: 'center',
                md: 'justify'
              }
            }}
            >
                El "Protocolo de Actuación" del Tuzobús atiende contingencias como lo son: emergencias médicas, accidentes dentro de las estaciones, infracciones cívicas o posibles delitos y algunas otras situaciones que las personas usuarias consideren que les afectan.
            </Typography>
        </Grid>
        <Grid item 
          xs={12} 
          md={7}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} 
        >
          <img 
            alt="/"
            src="/img/codigos/Tuzo.png"
            style={{
              width: '100%',
              height: '400px'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
};