// CustomScrollbar.js
import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const ScrollbarContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.gold} ${theme.palette.background.paper}`,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.paper,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.wine,
    borderRadius: '4px',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

const CustomScrollbar = ({ children, ...rest }) => {
  return <ScrollbarContainer {...rest}>{children}</ScrollbarContainer>;
};

export default CustomScrollbar;
