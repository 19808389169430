

export const troncalesData = (id, theme) => {

  const rutasTroncales = [
    {
      id: 'T-01',
      nombre: 'T-01 Exprés',
      shortName: 'T01',
      color: theme.palette.routes.green,
    },
    {
      id: 'T-02',
      nombre: 'T-02 Exprés',
      shortName: 'T02',
      color: theme.palette.routes.purpule,
    },
    {
      id: 'T-04',
      nombre: 'T-04 Exprés',
      shortName: 'T04',
      color: theme.palette.routes.orange,
    },
    {
      id: 'T-05',
      nombre: 'T-05 Parador',
      shortName: 'T05',
      color: theme.palette.routes.red,
    }
  ];

  return rutasTroncales.find((ruta) => ruta.id === id) || rutasTroncales[0];
};