import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function SwiperPagination({images}) {
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="my-swiper-container"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.ruta} alt={`imagen ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
