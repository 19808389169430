export const defaultProps = {
  // 20.07844762372143, -98.77499249211546
  // 20.05416178139832, -98.78763354157991
  center: {
    lat: 20.05416178139832, //20.121961,
    lng: -98.78763354157991 //-98.736782
  },
  zoom: 13
};

export const rutasTroncales = [
  {
    id: 'T-01',
    nombre: 'T-01 Exprés',
    color: '#50AF24',
  },
  {
    id: 'T-02',
    nombre: 'T-02 Exprés',
    color: '#6B096D',
  },
  {
    id: 'T-04',
    nombre: 'T-04 Exprés',
    color: '#FF8A00',
  },
  {
    id: 'T-05',
    nombre: 'T-05 Parador',
    color: '#C90606',
  }
];

export const rutasAlimentadoras = [
  {
    id: 'RA-01 A',
    nombre: "MATILDE / VALLE VERDE / E. MATILDE",
    color: '#BC955B',
    files: ['feature_72']
  },
  {
    id: 'RA-01 B',
    nombre: "EJIDOS LA PALMA / E. MATILDE",
    color: '#BC955B',
    files: ['feature_73']
  },
  {
    id: 'RA-02A',
    nombre: "FRACC. VILLA FONTANA / E. MATILDE",
    color: '#BC955B',
    files: ['feature_74']
  },
  {
    id: 'RA-02B',
    nombre: "BLVD. SAN ALFONSO / JAGÜEY TÉLLEZ",
    color: '#BC955B',
    files: ['feature_75']
  },
  {
    id: 'RA 03',
    nombre: "BOSQUES DE MATILDE / E. EFRÉN REBOLLEDO",
    color: '#BC955B',
    files: ['feature_76']
  },
  {
    id: 'RA 04',
    nombre: "LA PALMA / E. TERCERA EDAD",
    color: '#BC955B',
    files: ['feature_77']
  },
  {
    id: 'RA-05',
    nombre: "PARQUE URBANO / E. EJERCITO MEXICANO",
    color: '#BC955B',
    files: ['feature_78']
  },
  {
    id: 'RA 06',
    nombre: "HOGARES UNIÓN / E. EJERCITO MEXICANO",
    color: '#BC955B',
    files: ['feature_79']
  },
  {
    id: 'RA 07',
    nombre: "LA COLONIA / E. FELIPE ÁNGELES",
    color: '#BC955B',
    files: ['feature_80', 'feature_81']
  },
  {
    id: 'RA 08',
    nombre: "TUZOS / E. VICENTE SEGURA",
    color: '#BC955B',
    files: ['feature_82']
  },
  {
    id: 'RA 09',
    nombre: "PITAHAYAS / E. C. DE JUSTICIA",
    color: '#BC955B',
    files: ['feature_83']
  },
  {
    id: 'RA 10',
    nombre: "CAMELINAS / E. C. DE JUSTICIA",
    color: '#BC955B',
    files: ['feature_84']
  },
  {
    id: 'RA 11',
    nombre: "HUIXMÍ / E. C. DE JUSTICIA",
    color: '#BC955B',
    files: ['feature_85']
  },
  {
    id: 'RA 12',
    nombre: "MAGISTERIO / E. JUAN C DORIA",
    color: '#BC955B',
    files: ['feature_86']
  },
  {
    id: 'RA 13',
    nombre: "EL VENADO / E. HOSPITALES",
    color: '#BC955B',
    files: ['feature_87']
  },
  {
    id: 'RA 14',
    nombre: "NOPANCALCO / E. BICENTENARIO",
    color: '#BC955B',
    files: ['feature_88']
  },
  {
    id: 'RA 15-A',
    nombre: "SAN CAYETANO / E. C. DE AUTOBUSES",
    color: '#BC955B',
    files: ['feature_89']
  },
  {
    id: 'RA-15B',
    nombre: "ABETOS / E. C. DE AUTOBUSES",
    color: '#BC955B',
    files: ['feature_90']
  },
  {
    id: 'RA 15-C',
    nombre: "FRACC. COLOSIO / E. C. DE AUTOBUSES",
    color: '#BC955B',
    files: ['feature_91']
  },
  {
    id: 'RA 16',
    nombre: "SAN CARLOS / E. ZONA PLATEADA",
    color: '#BC955B',
    files: ['feature_92']
  },
  {
    id: 'RA 17',
    nombre: "TEZONTLE / HOSPITAL GENERAL PACHUCA",
    color: '#BC955B',
    files: ['feature_93']
  },
  {
    id: 'RA 19',
    nombre: "REAL DEL VALLE / E. PREPA 1",
    color: '#BC955B',
    files: ['feature_94']
  },
]

// const files = [
//   'geojson/feature_1.geojson', // T-01
//   'geojson/feature_10.geojson', // T-02
//   'geojson/feature_40.geojson', // T-04
//   'geojson/feature_41.geojson', // T-05
//   'geojson/feature_72.geojson',
//   'geojson/feature_73.geojson',
//   'geojson/feature_74.geojson',
//   'geojson/feature_75.geojson',
//   'geojson/feature_76.geojson',
//   'geojson/feature_77.geojson',
//   'geojson/feature_78.geojson',
//   'geojson/feature_79.geojson',
//   'geojson/feature_80.geojson',
//   'geojson/feature_81.geojson',
//   'geojson/feature_82.geojson',
//   'geojson/feature_83.geojson',
//   'geojson/feature_84.geojson',
//   'geojson/feature_85.geojson',
//   'geojson/feature_86.geojson',
//   'geojson/feature_87.geojson',
//   'geojson/feature_88.geojson',
//   'geojson/feature_89.geojson',
//   'geojson/feature_90.geojson',
//   'geojson/feature_91.geojson',
//   'geojson/feature_92.geojson',
//   'geojson/feature_93.geojson',
//   'geojson/feature_94.geojson',
// ];