import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import SwiperPagination from '../../components/Swiper/SwiperPagination';

export const Certificados = () => {

  const images = [
    {
      ruta: 'img/codigos/Img2.png'
    },
    {
      ruta: 'img/codigos/Img3.png'
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        bgcolor: 'primary.gold',
        px: {
          xs: 2,
          md: 10,
        },
        py: 5,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >

          <Typography
            variant="h4"
            sx={{
              mt: 10,
              mb: 5,
              color: 'primary.white',
              textAlign: {
                xs: 'center',
                md: 'left',
              },
              fontWeight: 'bold',
            }}
          >
            SECTUR OTORGA CERTIFICACIÓN A TUZOBÚS
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: 'primary.white',
              textAlign: 'justify',
              mb: 3,
            }}
          >
            Certificación del Código de Conducta Nacional para la protección de las niñas, niños y
            adolescentes en el sector de los viajes el turismo, otorgado al Sistema Integrado de
            Transporte Masivo de Hidalgo, con una vigencia al 08 de junio del 2025.
            <br />
            Fue entregada protocolariamente a la Titular de la SEMOT en el pasado mes de agosto
            del 2023 por parte de la Secretaría de Turismo. El Sistema Integrado de Transporte
            Masivo, fue el primer sistema de transporte masivo del país que contó con la
            certificación emitida por la SECTUR sobre el Código de Conducta Nacional para la
            protección de las niñas, niños y adolescente en el sector de los viajes y el turismo.
            Esta certificación tiene por objetoo la identificación, prevención y denuncia de
            delitos como la trata de personas, salvaguardar la seguridad de las infancias en el
            Sistema Tuzobús.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <SwiperPagination
            images={images}
          />
        </Grid>
      </Grid>
    </Box>
  );
};