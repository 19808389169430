import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';

const ModalRecorrido = ({ open, onClose, handleRecorridoAceptar, handleRecorridoRechazar}) => {
  if (!open) return null;

  const handleBackdropClick = (event) => {
    // Evita cerrar el modal si se hace clic dentro del contenido del modal
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.5)',
      }}
      onClick={handleBackdropClick}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          height: 'auto',
          minHeight: 200,
          bgcolor: 'primary.white',
          borderRadius: 10,
          padding: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant='body1'
              sx={{
                textAlign: 'center',
                color: 'primary.black',
              }}
            >
              Bienvenido al Nuevo portal de Tuzobús
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant='h4'
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'primary.gold',
              }}
            >
              ¡Nuevo mapa Interactivo!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src="img/logos/img-mapa.png"
              alt="Logo sitmah"
              style={{
                width: '200px',
                height: 'auto',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                textAlign: 'center',
                color: 'primary.black',
              }}
            >
              Conoce las rutas, horarios, puntos de recarga y mucho más
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              sx={{
                color: 'primary.white',
                bgcolor: 'primary.wine',
                mt: 2,
                borderRadius: 10,
              }}
              onClick={handleRecorridoAceptar}
            >
              Comenzar Recorrido
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant='text'
              sx={{
                color: 'primary.gold',
                mt: 2,
              }}
              onClick={handleRecorridoRechazar}
            >
              No, gracias
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ModalRecorrido;
