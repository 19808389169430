import React from 'react';
import { Grid, Avatar, List, ListItem, ListItemText } from "@mui/material";

import { TitlePage } from "../../components/Title/TitlePage";

export const Piramide = () => {

  return (
    <>
      <TitlePage title={"PIRAMIDE DE LA MOVILIDAD"} />
      <Grid container
        style={{
            marginTop: '0px'
        }}
        sx={{
            padding: {
                md: '0px 80px',
                sm: '0px 20px',
                xs: '10px'
            },
            display: {
                md: 'flex',
            },
            justifyContent: {
                md: 'center'
            },
        }}
      >
        <Avatar src="/img/piramide/piramide.png" alt=""
          style={{
            borderRadius: '0px',
            padding: '20px'
          }}
          sx={{
            width: {
                md: '80%',
                xs: '100%'
            },
            height: {
                md: '75%',
                xs: '100%'
            }
          }}
        />
        <Grid item xs={12} md={6}>
            <List>
                <ListItem
                    style={{
                        textAlign: 'justify',
                        flexDirection: 'column',
                        minHeight: '300px',
                    }}
                >
                    <ListItemText>
                        La pirámide de movilidad nos ayuda a entender cuál es el orden 
                        correcto para cada uno de los usuarios de la movilidad, en ella se 
                        establecen las prioridades de tránsito para promover la equidad, el 
                        beneficio social y un menor daño al medio ambiente.
                    </ListItemText>
                    <ListItemText>
                        Los peatones y ciclistas ocupan los primeros niveles de la Pirámide 
                        de Movilidad, por eso es necesaria infraestructura que garantice su 
                        seguridad. 
                    </ListItemText>
                    <ListItemText>
                        En primer lugar están los peatones, pues realizan el 39% de los 
                        desplazamientos de la ciudad y en algún momento del día todos 
                        somos peatones. 
                    </ListItemText>
                    <ListItemText>
                        En segundo lugar, los ciclistas, ya que es un medio muy eficiente 
                        en distancias cortas, son económicos, no contamina, ocupa menos 
                        espacio y es un medio de transporte saludable, pero también uno 
                        de los más vulnerables. 
                    </ListItemText>
                </ListItem>
            </List>
        </Grid>
        <Grid item xs={12} md={6}>
            <List>
                <ListItem
                    style={{
                        textAlign: 'justify',
                        flexDirection: 'column',
                        minHeight: '300px'
                    }}
                >
                    <ListItemText>
                        Seguidos de los usuarios del transporte público, que utiliza menos 
                        espacio que los coches y tiene capacidad para transportar a un 
                        mayor número de personas; es más económico y favorece la 
                        intermodalidad con peatones. 
                    </ListItemText>
                    <ListItemText>
                        Es necesario controlar el transporte de carga para que sus 
                        actividades se realicen en lugares y momentos que no 
                        entorpezcan la circulación. Así como contar con espacios en los 
                        que puedan realizar su trabajo sin afectar a otros usuarios de la 
                        movilidad.
                    </ListItemText>
                    <ListItemText>
                        Al final el automóvil y las motocicletas por su alto consumo de 
                        espacio público y emisión de contaminantes en la ciudad.
                    </ListItemText>
                </ListItem>
            </List>
        </Grid>
      </Grid>
    </>
  );


};