import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { tramintesyServicios } from '../../constant/tramitesServicios';
import { CardTramite } from '../../components/CardTramite/CardTramite';

export const Discapacitado = () => {
  const tramite = tramintesyServicios.find((item) => item.value === 'discapacidad');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bgcolor: 'primary.white',
        mt: 5,
        px: {
          xs: 2,
          md: 10,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mt: 5,
          mb: 2,
          color: 'primary.wine',
          textAlign: {
            xs: 'center',
            md: 'left',
          },
          fontWeight: 'bold',
        }}
      >
        TARJETA PERSONAS CON DISCAPACIDAD
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="/img/tarjetas/tarjetaDiscapacitados.png"
            alt="Tarjeta Preferencial Adulto Mayor"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'primary.black',
              textAlign: 'justify',
              mb: 3,
            }}
          >
            Las personas que acrediten tener una Discapacidad Permanente, que vivan en la Ciudad
            de Pachuca y su Zona Metropolitana, obtengan la reducción del 50% del precio de la
            tarifa vigente por el uso del Sistema Tuzobús.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: 'primary.black',
              textAlign: 'justify',
            }}
          >
            Personas que acrediten tener una Discapacidad Permanente y que vivan dentro de
            los siguientes municipios. *Epazoyucan *Mineral del Monte *Pachuca de Soto *Mineral
            de la Reforma *San Agustín Tlaxiaca *Zapotlán de Juárez *Zempoala
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              alignItems: 'center',
              justifyContent: {
                xs: 'center',
                md: 'space-around',
              },
              mt: 5,
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: 'primary.gold',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              REALIZA TU TRÁMITE
            </Typography>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                },
                mt: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <CardTramite item={tramite} />
            </Box>
          </ Box>
        </Grid>
      </Grid>
    </Box >
  );
};


