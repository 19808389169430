/**
 * @param {Date} date
 * @returns {string}
 * @description Format date to long format (e.g. 2021-09-15T00:00:00.000Z to September 15, 2021)
 */

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString('mx-MX', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export const formatDateTime = (date) => {
  return new Date(date).toLocaleDateString('mx-MX', {
    hour: 'numeric',
    minute: 'numeric',
  });
}