import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

const ReglasCV = () => {
    return(
        <>
            <Grid container
                style={{
                    marginTop: '50px'
                }}
                sx={{
                    padding: {
                        md: '0px 80px',
                        sm: '0px 20px',
                        xs: '10px'
                    }
                }}
            >
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >1</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Cuando pagas por un boleto adquieres el derecho de llegar a tu destino sano y salvo. Pero también, ciertas obligaciones.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >2</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Cuando viajes en transporte masivo, procura siempre respetar tres normas básicas de convivencia: no grito, no empujo y no corro.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >3</Typography>
                    </Stack>
                    <Typography variant="body2">
                    Procura EVITAR comer en los trayectos, ya que los residuos de comida pueden generar malos olores y las unidades dan el aspecto de que no fueron limpiadas.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >4</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Siempre, pero siempre, cede el lugar a personas de la tercera edad, con algún tipo de discapacidad y embarazadas.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >5</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Recuerda que eres tú con los demás. Si viajas con algún amigo, no grites ni seas tan efusivo. Tú no sabes si la persona que va a tu lado tuvo un mal día o está enferma.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >6</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Aunque se escuche trillado: EVITA tirar basura. Este es un hábito universal que todos debemos fomentar todos los días.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >7</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Si eres artista, procura "evitar intentar dejar una obra de arte en algún asiento o ventana del autobús en el que viajes", en forma de rayón.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >8</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Anticipa tu bajada y subida. Recuerda que hacerlo de forma tardía podría ser causa de un accidente.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >9</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Nunca viajes sin pagar. Sí, hay mucha gente que se cree muy "inteligente" por subirse a un autobús y viajar "gratis". Eso no es inteligencia, es deshonestidad.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}
                    style={{
                        display: 'flex',
                        alignItems: "center",
                        padding: "20px 40px 20px 0px",
                        textAlign: 'justify'
                    }}
                >
                    <Stack
                        style={{
                            borderRadius: "50%",
                            marginRight: '15px'
                        }}
                        sx={{
                            backgroundColor: "primary.wine",
                        }}
                    >
                        <Typography variant="h4"
                            style={{
                                fontWeight: 'bold',
                                textAlign: "center",
                                width: '75px',
                                height: '75px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            sx={{
                                color: "primary.gold",

                            }}
                        >10</Typography>
                    </Stack>
                    <Typography variant="body2">
                        Disfruta tu viaje. Sí. aunque a veces el transporte público puede estar lleno, siempre toma una actitud colaborativa, para que tu trayecto sea menos pesado.
                    </Typography>
                </Grid>

                <Grid item xs={12}
                    style={{
                        textAlign: "justify"
                    }}
                >
                    <Typography variant="body1">
                        Sin duda, seguir estos puntos te ayudará a que todas tus experiencias en cualquier tipo de transporte público sean placenteras. O, al menos, no las sufras tanto.
                    </Typography>
                    <ul>
                        <li>Exclusividad del carril confinado</li>
                    </ul>
                    <Typography variant="body1">
                        Esta medida busca mejorar los tiempos de traslado y la seguridad vial, al prevenir incidentes de tránsito causados por invasiones al carril confinado.
                    </Typography>
                    <ul>
                        <li> Tiempo de frenado de un Gran Vial</li>
                    </ul>
                    <Typography variant="body1">
                        El sistema de frenado constituye uno de los sistemas de seguridad más importantes en los vehículos, en el caso del transporte 
                        público es importante mencionar que es un medio de control de la velocidad de avance. En esencia, frenar implica aminorar la 
                        rotación de las ruedas, que se refleja en una menor velocidad del vehículo gracias al contacto llanta-piso, esto como la medida de 
                        seguridad, más importante dentro del Servicio Público de Transporte Masivo de Pasajeros esto en relación a la velocidad y el peso 
                        de la unidad del sistema Tuzobús.
                    </Typography>

                    <Stack
                        style={{
                            backgroundImage: "url('/img/codigos/imgCV2.png')",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            width: "100%",
                            height: "400px"
                        }}
                    >
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
};

export default ReglasCV;