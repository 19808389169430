import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const SwiperComponent = ({ rutasFotos }) => {

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        color='primary.main'
        pagination={{
          clickable: true,
        }}
        navigation={false}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="Imagenes de Referencia"
      >
        {
          rutasFotos?.map((ruta, index) => (
            <SwiperSlide key={index}>
              <img src={ruta} alt={index} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
}

export default SwiperComponent;
