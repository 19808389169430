import { useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Grid, IconButton, Button, Typography } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { CustomPopover } from '../Popover/CustomPopover';
import SwiperComponent from '../Swiper/Swiper';
import WifiIcon from '@mui/icons-material/Wifi';
import { PopoverAlimentadora } from '../Popover/PopoverAlimentadora';

const EstacionIcon = ({ onClickModal, data, color }) => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const anchorEl = null
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleTogglePopover = () => {
    setPopoverOpen((prev) => !prev);
    if (popoverOpen) {
      window.scrollTo({
        top: anchorRef.current.offsetTop - 200,
        behavior: 'smooth',
      });
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const colorIcon = () => {
    if (data?.type === 'troncal') {
      return color ? color : theme.palette.primary.wine;
    } else {
      return 'none';
    }
  };

  return (
    <Box
      sx={{
        color: 'white',
        width: color ? '55px' : '50px',
        height: color ? '55px' : '50px',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: colorIcon(),
      }}
      aria-describedby={id}
      variant="contained"
      ref={anchorRef}
    >
      <IconButton
        aria-label="bus"
        size="large"
        sx={{
          width: '40px',
          height: '40px',
        }}
        onClick={handleTogglePopover}
        ref={anchorRef}
      >
        {
          data?.imagen_on ?
            <>
              <img
                src={data?.imagen_on}
                alt="logo"
                style={{
                  width: '50px',
                  height: '50px',
                }}
              />
              {
                data?.wifi &&
                <Box
                  sx={{
                    position: 'absolute',
                    left: '100%',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '10px',
                    height: '50px',
                    color: theme.palette.primary.white,
                    transform: 'translate(-30%, -30%)',
                  }}
                >
                  <WifiIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: theme.palette.primary.blueIcon,
                      borderRadius: '50%',
                    }}
                  />
                </Box>
              }
            </>
            : data?.type === 'puntosRecarga' ?
              <img
                src={'img/icons/markerPuntosRecarga.svg'}
                alt="logo"
                style={{
                  width: '50px',
                  height: '50px',
                }}
              />
              : data?.type === 'alimentadora' ?
                <img
                  src={'img/icons/markerAlimentadora.svg'}
                  alt="logo"
                  style={{
                    width: '50px',
                    height: '50px',
                  }}
                />
                : <DirectionsBusIcon />
        }
      </IconButton>

      {
        data?.type === 'troncal' &&
        <Box>
          <CustomPopover
            anchorEl={anchorRef.current}
            open={popoverOpen}
            onClose={handleClosePopover}
          >
            <Card
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 4,
                minWidth: {
                  xs: '180px',
                  md: '300px',
                },
                width: {
                  xs: '180px',
                  md: '300px',
                }
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '150px',
                  width: '100%',
                  backgroundColor: theme.palette.primary.wine,
                  padding: 0,
                }}
              >
                <SwiperComponent rutasFotos={data?.fotos_referencia} />
              </CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={1}
              >
                <Grid
                  item
                  xs={6}
                  md={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={data?.estatus ? data?.imagen_on : data?.imagen_off}
                    alt="logo"
                    style={{ width: 'auto', height: '50px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    pl: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary.text.primary"
                    sx={{
                      ml: 1,
                      textAlign: 'left',
                      fontSize: {
                        xs: '10px',
                        md: '14px',
                      },
                    }}
                  >
                    {data?.nombre}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      width: {
                        xs: '80px',
                        md: 'auto',
                      },
                      borderRadius: 4,
                      px: {
                        md: 1,
                      },
                      backgroundColor: data?.estatus ? theme.palette.green.secondary : theme.palette.primary.wine,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: data?.estatus ? theme.palette.green.contrastText : theme.palette.primary.wine,
                      }}
                    >
                      {data?.estatus ? 'Abierta' : 'Cerrada'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: {
                      xs: '5px',
                      md: '10px',
                    }
                  }}
                >
                  <Button
                    variant="outlined"
                    size='small'
                    onClick={() => {
                      onClickModal();
                      handleClosePopover();
                    }}
                    sx={{
                      color: theme.palette.primary.wine,
                      borderColor: theme.palette.primary.wine,
                      borderRadius: 5,
                    }}
                  >
                    Ver más
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </CustomPopover>
        </Box>
      }

      {
        data?.type === 'alimentadora' &&
        <Box>
          <PopoverAlimentadora
            anchorEl={anchorRef.current}
            open={popoverOpen}
            onClose={handleClosePopover}
          >
            <Card
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 4,
                minWidth: {
                  xs: '180px',
                  md: '300px',
                },
                width: {
                  xs: '180px',
                  md: '300px',
                }
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={1}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary.wine"
                    sx={{
                      textAlign: 'left',
                      fontSize: {
                        xs: '10px',
                        md: '14px',
                      },
                    }}
                  >
                    <strong>{data?.nombre}</strong>
                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: {
                      xs: '5px',
                    }
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: 'justify',
                      color: theme.palette.primary.black,
                    }}
                  >
                    <strong>Horario:</strong> {data?.horario}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: {
                      xs: '5px',
                    }
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: 'justify',
                      color: theme.palette.primary.black,
                    }}
                  >
                    <strong>Dirección:</strong> {data?.direccion}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </PopoverAlimentadora>
        </Box>
      }

      {
        data?.type === 'puntosRecarga' &&
        <Box>
          <CustomPopover
            anchorEl={anchorRef.current}
            open={popoverOpen}
            onClose={handleClosePopover}
          >
            <Card
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 4,
                minWidth: {
                  xs: '180px',
                  md: '300px',
                },
                width: {
                  xs: '180px',
                  md: '300px',
                }
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '150px',
                  width: '100%',
                  backgroundColor: theme.palette.primary.wine,
                  padding: 0,
                }}
              >
                <SwiperComponent rutasFotos={data?.fotos_referencia} />
              </CardContent>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                padding={1}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    pl: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary.wine"
                    sx={{
                      ml: 1,
                      textAlign: 'left',
                      fontSize: {
                        xs: '10px',
                        md: '14px',
                      },
                    }}
                  >
                    {data?.nombre}
                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: {
                      xs: '5px',
                      md: '10px',
                    }
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: 'justify',
                      color: theme.palette.primary.black,
                    }}
                  >
                    Dirección: {data?.direccion}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </CustomPopover>
        </Box>
      }

    </Box>
  );
};

export default EstacionIcon;