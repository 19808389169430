import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import CodigoConducta from "./pages/CodigoConducta";
import CodigoTuzobus from "./pages/CodigoTuzobus";
import TarifasPreferenciales from "./pages/Tarifas";
import CaminoSeguro from "./pages/CaminoSeguro";
import AppTuzo from "./pages/AppTuzo";
import CulturaVial from "./pages/CulturaVial";
import NavAppBar from "./components/AppBar/AppBar";

export default function Router() {

  return (
    <BrowserRouter>
      <React.Fragment>
        <NavAppBar />
      </React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tarifas" element={<TarifasPreferenciales />} />
        <Route path="/app-tuzo" element={<AppTuzo />} />
        <Route path="/codigo-conducta" element={<CodigoConducta />} />
        <Route path="/codigo-tuzobus" element={<CodigoTuzobus />} />
        <Route path="/camino-seguro" element={<CaminoSeguro />} />
        <Route path="/cultura-vial" element={<CulturaVial />} />
      </Routes>
    </BrowserRouter>
  );
}