import React from "react";
import { Stack, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const columns = [
    { id: 'name', label: 'TRAMO', minWidth: 170 },
    { id: 'code', label: ' Velocidad de desplazamiento inicial  (k/h)  (m/s)', minWidth: 100 },
    { id: 'population', label: ' Distancia de frenado (m)', minWidth: 100 },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const rows = [
    createData(' Téllez-Bicentenario', '65 km/h', "55 km/h"),
    createData('Bicentenario- Prepa 1', '40 km/h', "21 m"),
    createData('Prepa 1- Centro Histórico', '30 km/h', "12 m")
  ];

const TableCV = () => {

    return(
        <>
        <Grid container
            style={{
                marginTop: '50px',
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center"
            }}
            sx={{
                padding: {
                    md: '0px 80px',
                    sm: '0px 20px',
                    xs: '10px'
                }
            }}
        >
            <Stack
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <Typography variant="h5"
                    style={{
                        fontWeight: "bold",
                        textAlign: "center"
                    }}
                >
                    DISTANCIA DE FRENADO
                </Typography>
            </Stack>
            <Paper 
                sx={{ 
                    width: {
                        md: "75%",
                        xs: "100%"
                    },
                    overflow: 'hidden'
                }}
                style={{
                    borderTop: "5px solid #DEC9A3"
                }}
            >
                <TableContainer sx={{ mINHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ width: column.minWidth, textAlign: "center", fontWeight: "bold"}}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bolder"
                                        }}
                                    >
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
        </>
    )
};

export default TableCV;