import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rutaId: "",
  color: ""
};

export const troncalActiveSlice = createSlice({
  name: "troncalActive",
  initialState,
  reducers: {
    changedTroncalActive: (state, action) => {
      const { rutaId, color } = action.payload;
      state.rutaId = rutaId;
      state.color = color;
    }
  }
});


export const { changedTroncalActive } = troncalActiveSlice.actions;
export default troncalActiveSlice.reducer;