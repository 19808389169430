import React from 'react';
import { Nacional } from '../sections/CodigoConducta/Nacional';
import { Certificados } from '../sections/CodigoConducta/Certificados';


const CodigoConducta = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        marginTop: {
          xs: '16px',
          md: '66px',
        },
      }}
    >
      <Nacional />
      <Certificados />
    </div>
  );
}

export default CodigoConducta;