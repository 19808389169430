import { React, useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Stack, Avatar } from '@mui/material';
import theme from "../../theme";
//import useMediaQuery from '@mui/material/useMediaQuery';
import { TitlePage } from "../../components/Title/TitlePage";

export const Protocolos = () => {
  const [direction, setDirection] = useState("");
  //const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      if (newWidth <= 900) {
        setDirection("column");
      } else {
        setDirection("row");
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      spacing={0}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          marginBottom: 3,
        }}
      >
        <TitlePage title={'TIPOS DE PROTOCOLOS'} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Card
          sx={{
            backgroundColor: theme.palette.green.main,
            color: '#fff',
            minHeight: '400px',
            paddingLeft: {
              xs: '0%',
              md: '10%'
            }
          }}
        >
          <CardContent
            sx={{
              padding: {
                md: '24px 0px'
              },
            }}
          >
            <Stack
              direction={direction}
              spacing={2}
              sx={{
                display: {
                  md: 'flex'
                },
                justifyContent: {
                  md: 'center'
                },
                alignItems: {
                  md: 'center'
                }
              }}
            >
              <Grid item
                sx={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100% !important',
                  height: '100px !important',
                  with: {
                    md: 'fit-content !important'
                  }
                }}
              >
                <Avatar
                  sx={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.greenDark,
                  }}
                  alt="image-protocol"
                  src="/img/icons/codeGreenIcon.svg"
                />
              </Grid>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  width: '100%',
                  height: '70px',
                  color: '#fff',
                  backgroundColor: theme.palette.primary.greenDark,
                  paddingLeft: {
                    md: '75px'
                  }
                }}
              >
                Potocolo de acción inmediata
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              component="p"
            >
              Aquellas contingencias que afectan la operación del servicio, que suceden dentro de la infraestructura del sistema o que de alguna manera se ven involucradas en el Tuzobús.
            </Typography>
            <ul>
              <li>Percance vehicular Unidad Tuzobús - Vehículo Particular</li>
              <li>Percance vehicular Unidad Tuzobús - Unidad Tuzobús</li>
              <li>Percance vehicular Vehículo Particular - Infraestructura</li>
              <li>Unidad varada en carril confinado o en estaciones</li>
              <li>Marcha</li>
              <li>Bloqueo</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item
        xs={12}
        md={6}
        sx={{
          minHeight: '300px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundImage: `url("/img/codigos/img4.png")`,
            minHeight: '350px',
            width: '100%',
          }}
        />
      </Grid>
      <Grid item
        xs={12}
        md={6}
        sx={{
          minHeight: '300px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Stack
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundImage: `url("/img/codigos/img5.png")`,
            minHeight: '350px',
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            backgroundColor: theme.palette.primary.yellowLight,
            color: '#fff',
            minHeight: '400px',
            paddingLeft: {
              xs: '0%',
              md: '10%'
            }
          }}
        >
          <CardContent
            sx={{
              padding: {
                md: '24px 0px'
              },
            }}
          >
            <Stack
              direction={direction}
              spacing={2}
              sx={{
                display: {
                  md: 'flex'
                },
                justifyContent: {
                  md: 'center'
                },
                alignItems: {
                  md: 'center'
                }
              }}
            >
              <Grid item
                sx={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100% !important',
                  height: '100px !important'
                }}
                xs={{
                  with: {
                    md: 'fit-content !important'
                  }
                }}
              >
                <Avatar
                  sx={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.orange,
                  }}
                  alt="image-protocol"
                  src="/img/icons/codeOrangeIcon.svg"
                />
              </Grid>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  width: '100%',
                  height: '70px',
                  color: '#fff',
                  backgroundColor: theme.palette.primary.orange,
                  marginLeft: {
                    md: '-50px !important'
                  },
                  paddingLeft: {
                    md: '75px'
                  }
                }}
              >
                Potocolo de acción de código ambar
              </Typography>
            </Stack>
            <ul sx={{ color: '#000' }}>
              <li>Persona usuaria en estado inconveniente</li>
              <li>Persona usuaria con problemas de salud</li>
              <li>Persona usuaria lesionada</li>
              <li>Código naranja</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            backgroundColor: theme.palette.primary.redLight,
            color: '#fff',
            minHeight: '400px',
            paddingLeft: {
              xs: '0%',
              md: '10%'
            }
          }}
        >
          <CardContent
            sx={{
              padding: {
                md: '24px 0px'
              },
            }}
          >
            <Stack
              direction={direction}
              spacing={2}
              sx={{
                display: {
                  md: 'flex'
                },
                justifyContent: {
                  md: 'center'
                },
                alignItems: {
                  md: 'center'
                }
              }}
            >
              <Grid item
                sx={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100% !important',
                  height: '100px !important',
                  with: {
                    md: 'fit-content !important'
                  }
                }}
              >
                <Avatar
                  sx={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.redDark,
                  }}
                  alt="image-protocol"
                  src="/img/icons/codeRedIcon.svg"
                />
              </Grid>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  width: '100%',
                  height: '70px',
                  color: '#fff',
                  backgroundColor: theme.palette.primary.redDark,
                  marginLeft: {
                    md: '-50px !important'
                  },
                  paddingLeft: {
                    md: '75px'
                  }
                }}
              >
                Potocolo de acción de código rojo
              </Typography>
            </Stack>
            <ul>
              <li>Colisión grave</li>
              <li>Atropellado por unidad del sistema</li>
              <li>Atropellado por vehículo particular</li>
              <li>Deceso por incidente automovilístico que afecte la operación</li>
              <li>Acoso Sexual</li>
              <li>Amenaza de bomba</li>
              <li>Fallecimiento</li>
              <li>Incendio</li>
              <li>Persona extraviada</li>
              <li>Robos</li>
              <li>Asalto</li>
              <li>Acto vandálico</li>
              <li>Sismo</li>
              <li>Inundación</li>
              <li>Unidad Secuestrada</li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
      <Grid item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          minHeight: '300px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundImage: `url("/img/codigos/img6.png")`,
            height: '350px',
            width: '100%',
          }}
        />
      </Grid>

    </Grid>
  )
};