/**
 EXPEDICIÓN TARJETA PREFERENTE TUZOBÚS PARA LA PERSONA ADULTA MAYOR
https://ruts.hidalgo.gob.mx/ver/1504
EXPEDICIÓN TARJETA PREFERENTE TUZOBÚS PARA PERSONAS CON DISCAPACIDAD
https://ruts.hidalgo.gob.mx/ver/4856
EXPEDICIÓN DE TARJETÓN PARA PERSONAS CONDUCTORAS DEL TUZOBÚS
https://ruts.hidalgo.gob.mx/ver/1506
REPOSICIÓN DE LA TARJETA PREFERENTE TUZOBÚS
https://ruts.hidalgo.gob.mx/ver/1505
QUEJAS Y SUGERENCIAS DEL SERVICIO TUZOBÚS
https://ruts.hidalgo.gob.mx/ver/1502
 */


export const tramintesyServicios = [
  {
    description: 'EXPEDICIÓN TARJETA PREFERENTE TUZOBÚS PARA LA PERSONA ADULTA MAYOR',
    icon: 'img/tramitesServicios/adultos.svg',
    value: 'adultoMayor',
    url: 'https://ruts.hidalgo.gob.mx/ver/1504'
  },
  {
    description: 'EXPEDICIÓN TARJETA PREFERENTE TUZOBÚS PARA PERSONAS CON DISCAPACIDAD',
    icon: 'img/tramitesServicios/discapacidad.svg',
    value: 'discapacidad',
    url: 'https://ruts.hidalgo.gob.mx/ver/4856'
  },
  {
    description: 'EXPEDICIÓN DE TARJETÓN PARA PERSONAS CONDUCTORAS DEL TUZOBÚS',
    icon: 'img/tramitesServicios/conductores.svg',
    value: 'conductores',
    url: 'https://ruts.hidalgo.gob.mx/ver/1506'
  },
  {
    description: 'REPOSICIÓN DE LA TARJETA PREFERENTE TUZOBÚS',
    icon: 'img/tramitesServicios/reposicion.svg',
    value: 'reposicion',
    url: 'https://ruts.hidalgo.gob.mx/ver/1505'
  },
  {
    description: 'QUEJAS Y SUGERENCIAS DEL SERVICIO TUZOBÚS',
    icon: 'img/tramitesServicios/quejas.svg',
    value: 'quejas',
    url: 'https://ruts.hidalgo.gob.mx/ver/1502'
  },
  {
    description: 'SUGERENCIAS Y REPORTES CONTRA PERSONAL OPERATIVO, LIMPIEZA, VIGILANCIA Y TECNOLOGÍA DEL TUZOBÚS.',
    icon: 'img/tramitesServicios/sugerencias.svg',
    value: 'sugerencias',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScpjkxse9HEL9WxIBke1krx-uc7FDAsML2GFPXc7cfw2LmxXg/viewform?usp=sf_link'
  },
]