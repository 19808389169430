import React, { useEffect, useState, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import DrawerEstacion from '../../components/Drawer/DrawerEstacion';
import EstacionIcon from '../../components/EstacionIcon/EstacionIcon';
import toast from 'react-hot-toast';
import { Box } from '@mui/material';
import { GOOGLE_MAPS_API_KEY } from '../../config';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { defaultProps, rutasAlimentadoras } from '../../constant/constants';
import { reconstruirJSON } from '../../utils/reconstruirJSON';
import { lineasActiveAlimentadora } from '../../utils/lineasAlimentadoras';

export const Mapa = (props) => {
  const { handleCerrarMenu } = props;
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const rutasActive = useSelector((state) => state.rutasActivas);
  const troncalActiva = useSelector((state) => state.troncalActiva);
  const alimentadoraActiva = useSelector((state) => state.alimentadoraActiva);
  const [dataTemp, setDataTemp] = useState([]);
  const [troncalesData, setTroncalesData] = useState([]);
  const [alimentadorasData, setAlimentadorasData] = useState([]);
  const [puntosRecargaData, setPuntosRecargaData] = useState([]);
  const [lineasRutasAlimentadoras, setLineasRutasAlimentadoras] = useState([]);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    getDataJsons();
  }, []);

  useEffect(() => {
    const loadGeoJsonFiles = async () => {
      if (map) {
        map.data.forEach((feature) => map.data.remove(feature));
      }

      const filesTemp = ['geojson/feature_1.geojson', 'geojson/feature_40.geojson', ...lineasRutasAlimentadoras];

      const dataPromises = filesTemp.map(async (file) => {
        const response = await fetch(file);
        return response.json();
      });

      const data = await Promise.all(dataPromises);
      setGeoJsonData(data);
    };

    loadGeoJsonFiles();
  }, [lineasRutasAlimentadoras, alimentadoraActiva, map]);

  useEffect(() => {
    if (map && maps && geoJsonData.length > 0) {
      map.data.forEach((feature) => map.data.remove(feature));
      geoJsonData.forEach((geoJson) => {
        map.data.addGeoJson(geoJson);
      });

      map.data.setStyle({
        strokeColor: theme.palette.primary.wine,
        strokeWeight: 2,
      });
    }
  }, [map, maps, geoJsonData]);

  useEffect(() => {
    filtrarRutas();
  }, [rutasActive, alimentadoraActiva, troncalActiva, troncalesData, alimentadorasData, puntosRecargaData]);

  const getDataJsons = async () => {
    try {
      const responseTroncales = await fetch("https://cdn.hidalgo.gob.mx/gobierno/dependencias/tuzobus/jsons/troncales.json");
      if (responseTroncales.ok) {
        const data = await responseTroncales.json();
        const troncales = data.map((troncal) => ({
          ...troncal,
          icon: 'TroncalesIcon',
          type: 'troncal',
        }));
        setTroncalesData(troncales);
      } else {
        console.error('Error al traer las rutas troncales.');
      }

      const responseAlimentadoras = await fetch("https://cdn.hidalgo.gob.mx/gobierno/dependencias/tuzobus/jsons/alimentadoras.json");
      if (responseAlimentadoras.ok) {
        const data = await responseAlimentadoras.json();
        const alimentadoras = reconstruirJSON(data);
        setAlimentadorasData(alimentadoras);
      } else {
        setAlimentadorasData([]);
        console.error('Error al traer las rutas alimentadoras.');
      }

      const responsePuntosRecarga = await fetch("https://cdn.hidalgo.gob.mx/gobierno/dependencias/tuzobus/jsons/puntosRecarga.json");
      if (responsePuntosRecarga.ok) {
        const data = await responsePuntosRecarga.json();
        const puntosRecarga = data.map((puntoRecarga) => ({
          ...puntoRecarga,
          icon: 'PuntosRecargaIcon',
          type: 'puntosRecarga',
        }));
        setPuntosRecargaData(puntosRecarga);
      } else {
        console.error('Error al traer los puntos de recarga.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error al cargar los datos');
    }
  };

  const filtrarRutas = useCallback(() => {
    let rutasTemp = [...puntosRecargaData];

    if (troncalActiva?.rutaId) {
      const troncalesFiltradas = troncalesData.filter(ruta => ruta.servicios_troncal.includes(troncalActiva.rutaId));
      rutasTemp = [...troncalesFiltradas, ...rutasTemp];
    } else if (troncalActiva?.rutaId === null) {
      rutasTemp = [...rutasTemp, ...troncalesData];
    }

    if (alimentadoraActiva?.alimentadoraActive) {
      const alimentadorasFiltradas = alimentadorasData.filter(ruta => ruta.numero === alimentadoraActiva.alimentadoraActive);
      const lineasRuta = rutasAlimentadoras.find(ruta => ruta.id === alimentadoraActiva.alimentadoraActive);
      const finesRuta = lineasActiveAlimentadora([lineasRuta]);
      setLineasRutasAlimentadoras(finesRuta);
      rutasTemp = [...alimentadorasFiltradas, ...rutasTemp];
    } else if (alimentadoraActiva?.alimentadoraActive === null) {
      rutasTemp = [...rutasTemp];
      setLineasRutasAlimentadoras([]);
    }

    const rutasFiltradas = rutasTemp.filter(ruta => rutasActive.rutasActivas.includes(ruta.type));
    setDataTemp(rutasFiltradas);
    setMapKey(prevKey => prevKey + 1); // Forzar la recreación del componente de mapa
  }, [rutasActive, alimentadoraActiva, troncalActiva, troncalesData, alimentadorasData, puntosRecargaData]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedMarker(null);
  };

  const handleApiLoaded = ({ map, maps }) => {
    setMap(map);
    setMaps(maps);
  };

  return (
    <>
      <Box
        onClick={() => {
          handleCerrarMenu();
        }}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
          backgroundColor: 'primary',
        }}
      >
        <GoogleMapReact
          key={mapKey}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        >
          {dataTemp.map((item) => (
            <EstacionIcon
              key={item.nombre}
              lat={item.coordenadas.latitud}
              lng={item.coordenadas.longitud}
              data={item}
              color={troncalActiva.color ? troncalActiva.color : item.color}
              onClickModal={() => handleMarkerClick(item)}
            />
          ))}
        </GoogleMapReact>

      </Box>
      <DrawerEstacion
        open={drawerOpen}
        onClose={handleCloseDrawer}
        marker={selectedMarker}
      />
    </>
  );
};
