import React from 'react';
import { Grid,Typography, Avatar, List, ListItem, ListItemText } from "@mui/material";

import { TitlePage } from "../../components/Title/TitlePage";

export const Bicicleta = () => {

  return (
    <>
            <TitlePage title={"PORQUE USAR BICIBLETA EN TU CIUDAD"} />
            <Grid container
                style={{
                    marginTop: '0px'
                }}
                sx={{
                    padding: {
                        md: '0px 80px',
                        sm: '0px 20px',
                        xs: '10px'
                    },
                    display: {
                        md: 'flex',
                    },
                    justifyContent: {
                        md: 'center'
                    },
                    alignItems: {
                        md: 'center'
                    },
                }}
            >
                <Avatar src="/img/piramide/bicicleta.png" alt=""
                    style={{
                        borderRadius: '0px',
                        padding: '20px'
                    }}
                    sx={{
                        width: {
                            md: '80%',
                            xs: '100%'
                        },
                        height: {
                            md: '75%',
                            xs: '100%'
                        }
                    }}
                    />
            </Grid>
            <Grid container
                style={{
                    marginTop: '0px'
                }}
                sx={{
                    padding: {
                        md: '0px 80px',
                        sm: '0px 20px',
                        xs: '10px'
                    },
                    display: {
                        md: 'flex',
                    },
                    justifyContent: {
                        md: 'center'
                    },
                }}
            >
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem
                            style={{
                                textAlign: 'justify',
                                flexDirection: 'column'
                            }}
                        >
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>1. Reduce la contaminación</Typography>
                                El uso de la bicicleta favorece la reducción de los niveles de 
                                contaminación ambiental, por una parte, pero también los niveles 
                                de contaminación acústica. Por lo tanto, menos gases y menos 
                                ruido.
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>2. Ahorras dinero</Typography>
                                Usar la bici en la ciudad es sinónimo de ahorro de dinero. 
                                Ahorramos en la adquisición del vehículo y ahorramos en 
                                mantenimiento.
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>3. Ahorras tiempo en desplazamientos cortos</Typography>
                                Hace un momento hemos hablado del ahorro de dinero que 
                                supone el uso de la bicicleta en una urbe, y ahora es el turno del 
                                ahorro de tiempo. Para algunos, más importante que el propio 
                                dinero. En bicicleta es más fácil realizar desplazamientos cortos y 
                                llegar a los lugares sin encontrar atascos, calles cortadas, sin sitio 
                                para estacionar, por lo tanto, sin tanto estrés acumulado.
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>4. La vía pública tendrá más espacio</Typography>
                                La mayor parte del espacio público de las ciudades está pensado 
                                para los vehículos a motor. En la mayoría de las calles, más de la 
                                mitad del espacio se lo lleva la calzada. Las aceras quedan 
                                rezagadas a lado y lado. Además, una bicicleta ocupa 5 veces 
                                menos espacio que un coche. Un dato que nos permite 
                                dimensionar mejor el espacio que ocupan los vehículos a motor.
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem
                            style={{
                                textAlign: 'justify',
                                flexDirection: 'column'
                            }}
                        >
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>5. Mejora tu salud y te permite ponerte en forma</Typography>
                                La OMS recomienda, a los adultos de 18 a 64 años, que dediquen 
                                como mínimo “150 minutos semanales a la práctica 
                                EQUIDAD DE GÉNERO
                                de actividad física aeróbica, de intensidad moderada” o lo que es lo 
                                mismo: hacer 30 minutos de ejercicio moderado cinco veces por 
                                semana. Todo ello, con el fin de mejorar las funciones 
                                cardiorrespiratorias y musculares y la salud ósea y de reducir el 
                                riesgo de enfermedades no transmisibles y depresión.
                            </ListItemText>
                            <ListItemText>
                                En este sentido, la bicicleta puede ser nuestro gran aliado, como 
                                sugiere la misma Organización. Y es que, con el mero hecho de ir y 
                                volver del trabajo en bicicleta, teniendo en cuenta que tenemos 
                                unos 20-25 minutos hasta nuestro puesto, tendremos más que 
                                hecho el deporte diario que se recomienda para mejorar la salud.
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>6. Descubres nuevos rincones de la ciudad</Typography>
                                Usar la bicicleta en tu ciudad, te va a permitir descubrir y conocer 
                                nuevos rincones que hasta ahora no sabías que existían. Si te 
                                desplazas en coche, en autobús y sobre todo en metro, hay 
                                muchos inputs de la ciudad que se te escapan ya sea por la 
                                velocidad o por el mero hecho de ir encerrado en un vehículo.
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="body1" style={{fontWeight: 'bold'}}>7. Te ayuda a desconectar del trabajo</Typography>
                                La sensación de libertad que tienes al subirte a una bicicleta tiene 
                                efectos positivos después de un día de mucho estrés o trabajo. Hay 
                                muchos estudios que han demostrado que hacer ejercicio mejora 
                                la salud mental.
                                Pedalear unos minutos del trabajo a casa, por ejemplo, nos puede 
                                ayudar a liberar tensiones, a evadir el estrés o a relativizar 
                                problemas.
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
  );


};