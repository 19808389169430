import React from "react";
import { Grid, Avatar, Typography } from '@mui/material';

import { TitlePage } from "../../components/Title/TitlePage";

const HeaderCV = () => {
    return(
        <>
            <TitlePage title={"CULTURA VÍAL"} />
            <Grid container
                style={{
                    marginTop: '50px'
                }}
                sx={{
                    padding: {
                        md: '0px 80px',
                        sm: '0px 20px',
                        xs: '10px'
                    }
                }}
            >
                <Grid item xs={12} md={7}
                    style={{
                        display: 'flex',
                        minHeight: '350px',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Avatar src="/img/codigos/imgCV1.png" alt="" 
                        style={{
                            borderRadius: '0px'
                        }}
                        sx={{
                            padding: {
                                xs: '25px 0px'
                            },
                            width: {
                                xs: '100%'
                            },
                            height: {
                                xs: '100%'
                            }
                        }} 
                    />
                </Grid>
                <Grid item xs={12} md={5} style={{ textAlign: 'justify', lineHeight: '1.6' }}>
                    <Typography variant="h6" style={{marginBottom: '25px'}}>
                        Todos los días millones de personas utilizan el transporte público para llegar a sus destinos. Algunos cuidan las unidades en las que se trasladan, mientras que otros se empeñan en dañarlas para que cada vez sean menos funcionales.
                    </Typography>
                    <Typography variant="h6">
                        Ante este panorama, es conveniente que conozcamos los principales derechos y obligaciones de cualquier persona que se suba a una unidad de transporte masivo. 
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
};

export default HeaderCV;