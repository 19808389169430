import { Box, Typography } from "@mui/material";
import React from "react";

export const CardTramite = ({item}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        maxWidth: '400px',
        cursor: 'pointer',
        backgroundColor: 'primary.backgroundLight',
      }}
      onClick={() => window.open(item.url, '_blank')}
    >
      <img
        src={item.icon}
        alt={item.description}
        style={{ width: '100px', height: '100px' }}
      />
      <Typography
        variant="body2"
        sx={{
          ml: 2,
          color: 'primary.textLight',
          textAlign: 'left',
          fontWeight: 'bold',
        }}
      >
        {item.description}
      </Typography>
    </Box>
  )
}