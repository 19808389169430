import React from "react";
import { Box, Divider, Typography } from "@mui/material";

export const TitlePage = ({ title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        bgcolor: "primary.white",
        px: {
          xs: 2,
          md: 10,
        },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mt: 5,
          color: "primary.gold",
          textAlign: {
            xs: "center",
            md: "left",
          },
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Divider
        fullWidth
        sx={{
          mt: 2,
          bgcolor: "primary.gold",
          border: 3,
          borderColor: "primary.gold",
        }}
      />
    </Box>
  );
}
