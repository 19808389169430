import CustomDrawer from '../components/Drawer/CustomDrawer';
import { BeneficiosServicios } from '../sections/Home/TramitesServicios';
import { Piramide } from '../sections/Home/Piramide';
import { Bicicleta } from '../sections/Home/Bicicleta';
import { Equidad } from '../sections/Home/Equidad';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const Home = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));



  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: isXs ? '45px' : '54px',
      }}
    >
      <CustomDrawer />
      <BeneficiosServicios />
      <Piramide />
      <Bicicleta />
      <Equidad />
    </div>
  );
}

export default Home;