import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { tramintesyServicios } from '../../constant/tramitesServicios';
import { CardTramite } from '../../components/CardTramite/CardTramite';

export const BeneficiosServicios = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bgcolor: 'primary.white',
        padding: 5,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          mt: 10,
          mb: 5,
          color: 'primary.gold',
          textAlign: 'left',
        }}
      >
        TRÁMITES Y SERVICIOS
      </Typography>
      <Grid
        container
        spacing={3}
      >
        {
          tramintesyServicios.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              key={index}
            >
              <CardTramite item={item} />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );


};